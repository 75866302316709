
import { defineComponent } from 'vue';
import SidebarComponent from './presentation/SidebarComponent.vue';
import TicketPurchaseComponent from './TicketPurchaseComponent.vue';
import { ChargebeePlanIdEnum } from '@/enums/ChargebeePlanIdEnum';
import { getEnumFromString } from '@/utils/GeneralUtils';


export default defineComponent({
	components: {
		SidebarComponent,
		TicketPurchaseComponent,
	},
	props: {
		planId: {
			type: String                                                                                                                                                                                                                                                                                                                                                                     ,
			required: true,
		},
		canPurchaseMultipleTickets: {
			type: Boolean,
			required: true,
		},
	},
	computed: {
		planIdEnum() {
			return getEnumFromString<ChargebeePlanIdEnum>(this.planId);
		},
	},
});
