enum BeltColour {
	None = 0,
	SubWhite = 1,
	White = 2,
	Yellow = 3,
	Green = 4,
	Blue = 5,
	Red = 6,
	Black = 7,
}

export default BeltColour;