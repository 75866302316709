import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TicketPurchaseComponent = _resolveComponent("TicketPurchaseComponent")!
  const _component_SidebarComponent = _resolveComponent("SidebarComponent")!

  return (_openBlock(), _createBlock(_component_SidebarComponent, null, {
    default: _withCtx(() => [
      _createVNode(_component_TicketPurchaseComponent, {
        "plan-ids": [_ctx.planIdEnum],
        "can-purchase-multiple-tickets": _ctx.canPurchaseMultipleTickets
      }, null, 8 /* PROPS */, ["plan-ids", "can-purchase-multiple-tickets"])
    ]),
    _: 1 /* STABLE */
  }))
}