
import { defineComponent, nextTick } from 'vue';
import { mapState, mapActions } from 'pinia';
import type { Wiki } from '@/interfaces/WikiInterface';
import { useWikiStore } from '@/stores/wikiStore';
import { currentUser } from '@/data/currentUserData';
import { axiosClient } from '@/utils/AxiosUtil';

const urlRegex = '^https://fast.wistia.net/embed/iframe/([a-zA-Z0-9]*)';

export default defineComponent({
	// route events so that we update the pinia store to 
	// tell it what wiki should be active. We need the 
	// `beforeRouteEnter` for the initial load, which handles
	// when linking to a specific Wiki, and only happens once
	// on this route (even if the params change). THen we have
	// `beforeRouteUpdate` which runs on the param changes so
	// we can naviaget between the routes/wikis easily.
	beforeRouteEnter(to, _from, next) {
		next(vm => {
			const wikiId = parseInt(to.params.id as string) || 0;
			(vm as any).setCurrentWiki(wikiId);
		});
	},
	beforeRouteUpdate(to) {
		const wikiId = parseInt(to.params.id as string) || 0;
		this.setCurrentWiki(wikiId);
	},
	computed: {
		...mapState(useWikiStore, ['currentWiki', 'prevNextWiki']),
	},
	watch: {
		currentWiki: {
			handler(newVal: Wiki) {
				if(newVal.ContentBody) {
					nextTick(this.bindClicksOnDynamicContent);
					nextTick(this.bindWistiaVideoInteractions);
				}
			},
			immediate: true,
		},
	},
	methods: {
		bindWistiaVideoInteractions() {
			const div = this.$refs['wiki'] as HTMLElement;
			if(!div || !div.firstChild) {
				return;
			}

			window._wq = window._wq || [];

			div.querySelectorAll<HTMLIFrameElement>('iframe.wistia_embed').forEach((embed: HTMLIFrameElement) => {
				const url = embed.src;

				const capture = url.match(urlRegex);
				if (!capture || capture.length < 2) {
					return;
				}
				const videoId = capture[1];

				window._wq.push({
					id: videoId,
					onReady: (video: any) => {
						video.email(currentUser.Email);

						video.bind('play', () => {
							axiosClient.post('/curriculum/video-started');
						});

						video.bind('end', () => {
							axiosClient.post('/curriculum/video-ended');
						});
					},
				});
			});
		},
		bindClicksOnDynamicContent() {
			const div = this.$refs['wiki'] as HTMLElement;
			if(!div || !div.firstChild) {
				return;
			}
			div.querySelectorAll(`a[href*="/curriculum/wiki/view/"]`).forEach(el => {
				el.addEventListener('click', e => {
					e.preventDefault();

					const regex = /spaces\/\d+\/curriculum\/wiki\/view\/(\d+)\/([a-zA-Z0-9_-]+)/ig;
					const href = (e.target as HTMLLinkElement).href || ''; 
					const matches = regex.exec(href) || [];
					if(matches.length <= 1) {
						return;
					}
					const newId = parseInt(matches[1]);
					if(!newId) {
						return;
					}

					this.$router.push({
						name: 'wikiView',
						params: {
							id: newId,
							slug: matches[2],
						},
					});
				});
			});
		},
		handlePrevNext(isPrev: boolean) {
			const w = (isPrev ? this.prevNextWiki.prev : this.prevNextWiki.next);
			this.$router.push({
				name: 'wikiView',
				params: {
					id: w!.ContentID,
					slug: w!.ContentStub,
				},
			});
		},
		...mapActions(useWikiStore, ['setCurrentWiki']),
	},
});
