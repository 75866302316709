
import { defineComponent } from 'vue';
import type { PropType } from 'vue';
import type { UserWithBeltAndLocation } from '@/interfaces/UserWithBeltAndLocationInterface';
import PersonAvatar from '@/components/PersonAvatarComponent.vue';

export default defineComponent({
	components: {
		PersonAvatar,
	},
	props: {
		user: {
			type: Object as PropType<UserWithBeltAndLocation>,
			default: {} as UserWithBeltAndLocation,
		},
	},
	emits: {
		mapIconClicked(user: UserWithBeltAndLocation) {
			return !!user;
		},
	},
})
