
import { defineComponent } from 'vue';
import { axiosClient } from '@/utils/AxiosUtil';
import type { ApiResponseMagicLinks } from '@/interfaces/ApiResponseInterface';
import type { ApiResponseMagicLink } from '@/interfaces/ApiResponseInterface';
import type { ApiResponseUsers } from '@/interfaces/ApiResponseInterface';
import type { MagicLink } from '@/interfaces/MagicLinkInterface';
import type { User } from '@/interfaces/UserInterface';
import VueMultiselect from 'vue-multiselect';

export default defineComponent({
	components: { VueMultiselect },
	data() {
		return {
			magicLinks: [] as MagicLink[],
			expiryChoice: '',
			userChoice: {} as User,
			userSearchResults: [] as User[],
			historicalDataLoaded: false,
			sortColumn: '',
			sortDirection: true,
		}
	},
	computed: {
		selectableExpiryDates() {
			const expiryDates = new Map();
			expiryDates.set('1 Hour', 3600);
			for (let i = 1; i < 8; i++) {
				let dayInSeconds = (24*60*60*i);
				expiryDates.set(`${i} Day${i > 1 ? 's' : ''}`, dayInSeconds);
			}
			return expiryDates;
		},
		sortedMagicLinks() {
			return [...this.magicLinks].sort(this.sortComparison());
		},
	},
	beforeMount() {
		this.sortColumn = 'email';
		this.getMagicLinks();
		this.expiryChoice = this.selectableExpiryDates.entries().next().value[1];
	},
	methods: {
		async getMagicLinks() {
			const { data } = await axiosClient.post<ApiResponseMagicLinks>('/login/auth/get-current-magic-links');
			if(!data.success) {
				return window.alert('Sorry, there was an error loading the magic links.')
			}
			this.magicLinks = data.magicLinks;
		},
		async getHistoricalMagicLinks() {
			const { data } = await axiosClient.post<ApiResponseMagicLinks>('/login/auth/get-historical-magic-links');
			if(!data.success) {
				return window.alert('Sorry, there was an error loading the historical magic links.')
			}
			this.magicLinks.push(...data.magicLinks);
			this.historicalDataLoaded = true;
		},
		async generateMagicLink() {
			if (this.userChoice === null || this.userChoice.UserID === undefined) {
				alert('Please select a user.');
				return;
			}
			const { data } = await axiosClient.post<ApiResponseMagicLink>('/login/auth/generate-magic-link', {
				userId: this.userChoice.UserID,
				expirySeconds: this.expiryChoice,
			});
			if(!data.success) {
				return window.alert(data.message)
			}
			this.magicLinks.push(data.magicLink);
		},
		async cancelMagicLink(token: string) {
			const { data } = await axiosClient.post<ApiResponseMagicLink>('/login/auth/cancel-magic-link', {
				token,
			});
			if(!data.success) {
				return window.alert('Sorry, there was an error cancelling the magic link.')
			}
			this.magicLinks = this.magicLinks.filter(ml => {
				return ml.Token !== token;
			});
			this.magicLinks.push(data.magicLink);
		},
		copyMagicLink(token: string, email: string) {
			navigator.clipboard.writeText(`${window.location.origin}/login/auth/magic-link/?token=${token}&email=${email}&redirectPath=/spaces/2/business-school`);
		},
		getStatusDisplay(magicLink: MagicLink) {
			let now = new Date();
			if (magicLink.Status === 'unused' && now > magicLink.ExpiryDate) {
				return 'expired';
			}
			return magicLink.Status;
		},
		async magicLinkUserSearch(name: string) {
			if (name.length < 5) {
				return;
			}
			const { data } = await axiosClient.post<ApiResponseUsers>('/login/auth/magic-link-user-search', {
				name,
			});
			if(!data.success) {
				return window.alert('Sorry, there was an error performing the user search.')
			}
			this.userSearchResults = data.users;
		},
		sortMagicLinks(sortColumn: string) {
			if (sortColumn === this.sortColumn) {
				this.sortDirection = !this.sortDirection;
			}
			else {
				this.sortColumn = sortColumn;
				this.sortDirection = true;
			}
		},
		sortComparison() {
			let sortDirection = this.sortDirection;
			if (this.sortColumn === 'email') {
				return function(a: MagicLink, b: MagicLink) {
					if(sortDirection) {
						return a.Email.localeCompare(b.Email);
					}
					return b.Email.localeCompare(a.Email);
				}
			}
			if (this.sortColumn === 'status') {
				return function(a: MagicLink, b: MagicLink) {
					if(sortDirection) {
						return a.Status.localeCompare(b.Status);
					}
					return b.Status.localeCompare(a.Status);
				}
			}
			if (this.sortColumn === 'created') {
				return function(a: MagicLink, b: MagicLink) {
					if(sortDirection) {
						return a.DateCreated.getTime() - b.DateCreated.getTime();
					}
					return b.DateCreated.getTime() - a.DateCreated.getTime();
				}
			}
		},
	},
});
