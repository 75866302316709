import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9e255abc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "inner" }
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["thought-announcement", {
			'thought-announcement-space-specific': !!_ctx.announcement.Space,
			'hiding': _ctx.announcement.isHiding,
		}])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("span", {
        innerHTML: _ctx.announcement.Copy
      }, null, 8 /* PROPS */, _hoisted_2),
      _createElementVNode("i", {
        class: "icon-remove hide-announcement",
        title: "Hide",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.hideAnnouncement(_ctx.announcement)))
      })
    ])
  ], 2 /* CLASS */))
}