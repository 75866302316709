import { expertiseData } from '@/data/expertiseData';

const separator = ', ';

export const profileScript = () => {
	const cityCombobox = document.querySelector('#ctl00_ctl00_ctl00_ContentPlaceHolder1_MainBodyPlaceHolder1_MainBodyPlaceHolder1_EditUser1_ddlMetro') as HTMLSelectElement;
	if (cityCombobox) {
		cityCombobox.style.display = 'none';
		window.addEventListener(
			'load',
			function() {
				// -1 is Other and 0 is Please Select. We always want this choice to be Other so the city input is visible
				if (cityCombobox.value !== '-1')
				{
					const cityInput = document.querySelector('#ctl00_ctl00_ctl00_ContentPlaceHolder1_MainBodyPlaceHolder1_MainBodyPlaceHolder1_EditUser1_txtLocation') as HTMLInputElement;
					if (cityCombobox.value !== '0' && cityCombobox.options[cityCombobox.selectedIndex]) {
						const selectedCity = cityCombobox.options[cityCombobox.selectedIndex].text;
						cityInput.value = selectedCity;
					}
					cityCombobox.value = '-1';
					cityCombobox.dispatchEvent(new Event('change'));
				}
				const countryComboBox = document.querySelector('#ctl00_ctl00_ctl00_ContentPlaceHolder1_MainBodyPlaceHolder1_MainBodyPlaceHolder1_EditUser1_ddlCountry') as HTMLSelectElement;
				countryComboBox.onchange = () => resetCityComboBox();

				const stateComboBox = document.querySelector('#ctl00_ctl00_ctl00_ContentPlaceHolder1_MainBodyPlaceHolder1_MainBodyPlaceHolder1_EditUser1_ddlState') as HTMLSelectElement;
				stateComboBox.onchange = () => resetCityComboBox();

				function resetCityComboBox() {
					cityCombobox.value = '-1';
					cityCombobox.dispatchEvent(new Event('change'));
				}
			},
			false,
		);
	}

	const expertise = document.querySelector('.axero-profile-panes .axero-user-expertise');
	if (!expertise) {
		return;
	}

	const input = expertise.querySelector('input');
	if(!input) {
		return;
	}

	input.style.display = 'none';
	let expertiseCSV = input.value.split(separator);

	expertiseData.forEach(function (checkboxName: string) {
		const containerDiv = document.createElement('div');
		const checkbox = document.createElement('input');
		checkbox.type = 'checkbox';
		checkbox.id = checkboxName;
		if (expertiseCSV.includes(checkboxName)) {
			checkbox.checked = true;
		}
		checkbox.onclick = function(event) {
			expertiseCSV = input.value.split(separator);
			if (checkbox.checked) {
				if (input.value.length === 0) {
					input.value = checkboxName;
				}
				else if (expertiseCSV.length < 3) {
					input.value += separator + checkboxName;
				}
				else {
					event.preventDefault();
				}
			}
			else {
				if (expertiseCSV.length === 1) {
					input.value = input.value.replace(checkboxName, '');
				}
				else if (expertiseCSV[0] === checkboxName) {
					input.value = input.value.replace(checkboxName + separator, '');
				}
				else {
					input.value = input.value.replace(separator + checkboxName, '');
				}
			}
		}

		containerDiv.appendChild(checkbox);

		const label = document.createElement('label');
		label.htmlFor = checkboxName;
		label.appendChild(document.createTextNode(checkboxName));

		containerDiv.appendChild(label);
		expertise.appendChild(containerDiv);
	});
}