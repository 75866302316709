export const curriculum = {
	api: {
		// Having a default value here allows us to mock this
		// when Axios tries to post to it since it needs a URL
		// to know which call to mock with what, and an empty
		// string I think mocks calls to all endpoints.
		url: window.tlConstants?.curriculum.api.url || 'localhost/learning',
		user: window.tlConstants?.curriculum.api.user,
		pass: window.tlConstants?.curriculum.api.pass,
	},
};

export const currentSpace = {
	...window.thoughtCurrentSpace,
}