
import { defineComponent } from 'vue';
import ToolTipComponent from '@/components/ToolTipComponent.vue';
import checkboxIcon from '@/images/icons/icon-checkbox.svg?inline';

export default defineComponent({
	components: {
		ToolTipComponent,
		checkboxIcon,
	},
	props: {
		streakHealth: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			daysOfTheWeek: ['M', 'T', 'W', 'T', 'F', 'S', 'S'],
		}
	},
	computed: {
		streakHealthText(): string {
			switch(this.streakHealth) {
			case 'green':
				return "Well done. You can keep your streak alive even if you don't add any activity in next two days.";
			case 'orange':
				return 'Keep going. You need to do something before midnight tomorrow to keep your streak alive.';
			case 'red':
				return 'Warning! Your streak will be lost unless you record an activity before midnight tonight.';
			default:
				return 'Enter an activity to start your next Dashboard Streak.'
			}
		},
	},
})
