
import { defineComponent } from 'vue';
import { axiosClient } from '@/utils/AxiosUtil';
import type { ApiResponse } from '@/interfaces/ApiResponseInterface';
import type { ApiResponseBroadcastAdmin } from '@/interfaces/ApiResponseInterface';
import type { ApiResponseBroadcast } from '@/interfaces/ApiResponseInterface';
import type { Broadcast } from '@/interfaces/BroadcastInterface';
import type { Space } from '@/interfaces/SpaceInterface';

const broadcastEmpty = {
	VideoURL: '',
	Copy: '',
	SpaceID: -1,
} as Broadcast;

export default defineComponent({
	data() {
		return {
			broadcasts: [] as Broadcast[],
			spaces: [] as Space[],
			spaceIdChoice: -1,
			videoUrlChoice: '',
			copyChoice: '',
			newBroadcast: { ...broadcastEmpty },
		}
	},
	computed: {
		canAddBroadcast() {
			return (this.newBroadcast.SpaceID > 0 && !!this.newBroadcast.VideoURL && !!this.newBroadcast.Copy);
		},
	},
	beforeMount() {
		this.loadBroadcasts();
	},
	methods: {
		async loadBroadcasts() {
			const { data } = await axiosClient.post<ApiResponseBroadcastAdmin>('/broadcast/get-broadcasts-and-spaces');

			this.spaces = data.spaces;
			this.broadcasts = data.broadcasts;
		},
		async createBroadcast() {
			if (!this.canAddBroadcast) {
				return;
			}

			const { data } = await axiosClient.post<ApiResponseBroadcast>('/broadcast/create', {
				broadcastIn: this.newBroadcast,
			});

			if(!data.success) {
				return window.alert(data.message);
			}

			this.broadcasts.push(data.broadcast);
			this.newBroadcast = { ...broadcastEmpty };
		},
		async deleteBroadcast(broadcastId: number) {
			const { data } = await axiosClient.post<ApiResponse>('/broadcast/delete', {
				broadcastId: broadcastId,
			});

			if(!data.success) {
				return window.alert('Sorry, there was an error deleting broadcast.')
			}
			
			this.broadcasts = this.broadcasts.filter(b => {
				return b.ID !== broadcastId;
			});
		},
	},
});
