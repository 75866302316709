import { defineStore } from 'pinia';
import { axiosClient } from '@/utils/AxiosUtil';
import { ChargebeePlan } from '@/interfaces/ChargebeePlanInterface';
import { ApiResponseChargebeePlans } from '@/interfaces/ApiResponseInterface';
import { ApiResponseEventTicketPurchase } from '@/interfaces/ApiResponseInterface';
import { ChargebeePlanIdEnum } from '@/enums/ChargebeePlanIdEnum';
import type { ChargebeeCard } from '@/interfaces/ChargebeeCardInterface';
import { currentUser } from '@/data/currentUserData';

export const usePlanStore = defineStore({
	id: 'plan',
	state: () => {
		return ({
			loading: false,
			plans: [] as ChargebeePlan[],
		});
	},
	actions: {
		async getPlansWithAttendance(planIds: ChargebeePlanIdEnum[]) {
			this.loading = true;

			const { data: { success, plans } } = await axiosClient.post<ApiResponseChargebeePlans>('/login/public/chargebee/get-plans-with-customer-subscriptions', {
				planIds: planIds,
			});

			if(!success) {
				return window.alert('Sorry, there was an error loading the plans. Please contact us on info@thoughtleaders.com.au');
			}

			// remove plans that are new from the server
			this.plans = this.plans.filter(p => !plans.find(newPlan => newPlan.PlanId === p.PlanId));
			// add new plans from the server
			this.plans.push(...plans);
			this.loading = false;
		},
		async purchasePlan(planId: string, card: ChargebeeCard, country: string): Promise<boolean> {
			this.loading = true;

			const { data } = await axiosClient.post<ApiResponseEventTicketPurchase>('/login/public/chargebee/purchase-plan', {
				planId,
				cardId: card.Id,
				user: currentUser.UserID ? null : currentUser,
				country,
			});

			if(!data.success) {
				window.alert(data.message);
				return false;
			}

			if(data.subscriptionCreated) {
				this.plans.find(p => p.PlanId === planId)!.SubscriptionCount++;
			}

			this.loading = false;
			return true;
		},
	},
});
