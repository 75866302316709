
import { defineComponent } from 'vue';
import logoSvg from '@/images/logo.svg?inline';

export default defineComponent({
	components: { logoSvg },
	data() {
		return {
			tlcDomain: process.env.TLC_DOMAIN,
		}
	},
});
