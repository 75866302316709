import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-889aec30"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "axero-tag-cloud tl-axero-tag-cloud" }
const _hoisted_2 = { class: "ax-add-tags-popular-list" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "tags" }
const _hoisted_5 = {
  id: "TagHyperLink",
  class: "label",
  href: "#"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tagGroups, (tagGroup) => {
        return (_openBlock(), _createElementBlock("div", {
          key: tagGroup.TagGroupID,
          class: _normalizeClass(["tag-group", { 'open': tagGroup.isOpen }])
        }, [
          _createElementVNode("div", {
            class: "tag-group-header",
            onClick: ($event: any) => {tagGroup.isOpen = !tagGroup.isOpen;}
          }, _toDisplayString(tagGroup.TagGroupName), 9 /* TEXT, PROPS */, _hoisted_3),
          _createElementVNode("ul", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(tagGroup.Tags, (tag) => {
              return (_openBlock(), _createElementBlock("li", {
                key: tag.TagID
              }, [
                _createCommentVNode(" CF needs this ID so that id takes over the click event to add it to the CF tag list "),
                _createElementVNode("a", _hoisted_5, _toDisplayString(tag.TagName), 1 /* TEXT */)
              ]))
            }), 128 /* KEYED_FRAGMENT */))
          ])
        ], 2 /* CLASS */))
      }), 128 /* KEYED_FRAGMENT */))
    ])
  ]))
}