
import { defineComponent } from 'vue';
import { axiosClient } from '@/utils/AxiosUtil';
import ModalComponent from '@/components/ModalComponent.vue';
import type { ApiResponse } from '@/interfaces/ApiResponseInterface';
import type { ApiResponseBroadcast } from '@/interfaces/ApiResponseInterface';
import type { Broadcast } from '@/interfaces/BroadcastInterface';
	
export default defineComponent({
	components: { ModalComponent },
	data() {
		return {
			broadcast: {} as Broadcast,
			open: false,
		}
	},
	beforeMount() {
		this.loadBroadcast();
	},
	methods: {
		async loadBroadcast() {
			const { data } = await axiosClient.post<ApiResponseBroadcast>('/broadcast/get-user-broadcast');

			if (!data.broadcast) {
				return;
			}
			this.broadcast = data.broadcast;
			this.open = true;
		},
		async markRead() {
			await axiosClient.post<ApiResponse>('/broadcast/markread', { broadcastId: this.broadcast.ID });

			this.open = false;
		},
	},
});
