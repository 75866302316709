
// This component shows data from a users dashboard, taking it from the spreadsheet
// data that is JSONified and passed in as a prop. We use AJAX to get the users belt
// achievements. The data is broken into whole months.
import { defineComponent } from 'vue';
import { Bar } from 'vue-chartjs';
import { Chart, BarElement, LineController, type ChartOptions, type ChartData, ScaleOptions, ChartDataset, DefaultDataPoint } from 'chart.js';
import { addMonths, endOfMonth, format, startOfMonth } from 'date-fns';
import * as pluralize from 'pluralize';
import { axiosClient } from '@/utils/AxiosUtil';
import { activityTypes, ActivityTypeSale } from '@/data/activityTypeData';
import { beltLevels } from '@/data/beltLevelData';
import ActivityStyle from '@/enums/ActivityStyleEnum';
import type { Activity } from '@/interfaces/ActivityInterface';
import type { BeltAchievement } from '@/interfaces/BeltAchievementInterface';
import type { ApiResponseActivitiesInterface } from '@/interfaces/ApiResponseInterface';
import type { ActivityType } from '@/interfaces/ActivityTypeInterface';
import { BeltLevel } from '@/interfaces/BeltLevelInterface';
import { ActivityTypeTogglable } from '@/interfaces/ActivityTypeTogglableInterface';

Chart.register(BarElement, LineController);
const labelDateFormat = 'Y-MM'

function getPreviousBelt(belt: BeltLevel): BeltLevel {
	const index = beltLevels.indexOf(belt);
	if(index <= 0) {
		return beltLevels[0];
	}
	return beltLevels[index - 1];
}

const font = {
	family: 'Poppins,sans-serif',
};
const fontLarge = {
	...font,
	size: 16,
	weight: 'bold',
};

function labelScalingForMonetaryData(data: number[]): { divider: number, label: string } {
	const max = Math.max(...data);

	if(max > (1 * 1000 * 1000)) {
		return {
			divider: (1 * 1000 * 1000),
			label: '($M)',
		}
	}

	if(max > (9 * 1000)) {
		return {
			divider: (1 * 1000),
			label: '($000)',
		}
	}
	
	return {
		divider: 1,
		label: '$',
	}
}

export default defineComponent({
	components: { Bar },
	props: {
		userId: {
			type: Number,
			required: true,
		},
	},
	data() {
		const toggles = [...activityTypes.slice(0, 2), {
			ActivityStyle: ActivityStyle.Monetary,
			ID: -1,
			DisplayName: 'Accumulated Sales',
		}, ...activityTypes.slice(2)].map(t => {
			return {
				...t,
				hidden: true,
			} as ActivityTypeTogglable;
		});
		toggles[0].hidden = false;
		toggles[2].hidden = false;

		return {
			windowBigEnough: false,
			loading: true,
			achievements: [] as BeltAchievement[],
			// Ordered from oldest to most recent
			activities: [] as Activity[],
			toggles,
		}
	},
	computed: {
		// Divide monetary labels by 1000 if a single sale monetary activity is over 5000
		// or the accumulative is over 10000
		shouldDivideMonetaryByThou() {
			const monetaryActivities = this.activities.filter(a => a.ActivityType.ActivityStyle === ActivityStyle.Monetary);
			return monetaryActivities.some(a => a.NumberCompleted >= 5000) || (monetaryActivities.filter(a => a.ActivityType.ID === ActivityTypeSale.ID).reduce((acc, a) => acc + a.NumberCompleted, 0) >= 10000);
		},
		belts() {
			// Achievements are ordered by most recent to oldest (and are qunique), and since these
			// are annual belt achievements, this technically means it is from 
			// lowest belt color to highest, but testing this might not be 100% true, 
			// but it should be for users.
			if(this.achievements.length <= 0 || this.labels.months.length <= 0) {
				return [];
			}

			// todo
			// Testing to ensure it works with all kinds of combinations of end/start achievemtns
			
			let startOfMonthFirstActivityDate = startOfMonth(this.activities[0].CompletedDate);
			const now = new Date();
			const totalTime = endOfMonth(now).getTime() - startOfMonthFirstActivityDate.getTime();
			// Belts from first activity date (start of graph) to now, from lowest to highest
			const achievements = [...this.achievements].filter(a => a.Date >= startOfMonthFirstActivityDate && a.Date <= now).reverse();
			
			if(achievements.length <= 0) {
				return [];
			}
			
			const firstAchievement = achievements[0];
			return [
				{
					color: getPreviousBelt(firstAchievement.belt).colorValue,
					percent: ((firstAchievement.Date.getTime() - startOfMonthFirstActivityDate.getTime()) / totalTime) * 100,
				},
				...achievements.map((a, i) => {
					const endDate = (i >= (achievements.length - 1) ? endOfMonth(now) : achievements[i + 1].Date);
					return {
						color: a.belt.colorValue,
						percent: ((endDate.getTime() - a.Date.getTime()) / totalTime) * 100,
					}
				}),
			];
		},
		labels() {
			if(this.activities.length <= 0) {
				return {
					months: [],
					years: [],
				};
			}

			const yearObj = {} as any;
			let monthTotalCount = 0;

			// Create the x-axis labels from year and date values
			const monthLabels: string[] = [];
			// Loop from the first activity to now to create all date labels
			const now = new Date();
			var userTimezoneOffset = this.activities[0].CompletedDate.getTimezoneOffset() * 60000;
			var firstActivityDate = new Date(this.activities[0].CompletedDate.getTime() + userTimezoneOffset + (11*60*60*1000)); // add 11 hours to offset the server timezone
			let loopingDate = startOfMonth(firstActivityDate);
			
			while(loopingDate <= now) {
				const formattedDate = format(loopingDate, labelDateFormat);
				monthLabels.push(formattedDate);

				const year = format(loopingDate, 'Y');
				yearObj[year] = ++yearObj[year] || 1;
				monthTotalCount++;

				loopingDate = addMonths(loopingDate, 1);
			}

			const yearLabels = Object.keys(yearObj).map(year => {
				return {
					year: year,
					width: yearObj[year] / monthTotalCount * 100,
					isFullYear: yearObj[year] === 12,
				}
			}).sort((a, b) => a.year > b.year ? 1 : -1);

			return {
				months: monthLabels,
				years: yearLabels,
			};
		},
		shouldShowAccumulatedSalesAxes() {
			return this.toggles.find(t => t.ActivityStyle === ActivityStyle.Monetary && t.ID === -1 && !t.hidden) !== undefined;
		},
		shouldShowPhysicalAxes() {
			return !this.shouldShowAccumulatedSalesAxes;
		},
		chartData(): { data: ChartData<'bar'>, options: ChartOptions } {
			const d = {
				data: {
					datasets: [] as ChartDataset<'bar'>[],
					labels: this.labels.months,
				} satisfies ChartData<'bar', DefaultDataPoint<'bar'>, string>,
				options: {
					scales: {
						x: {
							grid: {
								drawOnChartArea: false,
							},
							ticks: {
								callback: function(val, _i, allTicks) {
									const formattedLabel = this.getLabelForValue(val as number);
									const date = new Date(formattedLabel + '-02');

									if(allTicks.length <= 12) {
										return format(date, 'MMM');
									}

									if(allTicks.length <= 24) {
										return format(date, 'MMM').substring(0, 1);
									}

									return '';
								},
								padding: 40,
								font,
							},
						},
						// always show the monetary activities axis and data
						monetary: {
							position: 'left',
							title: {
								display: true,
								font: fontLarge,
								text: 'Sales & Delivery',
							},
							beginAtZero: true,
							ticks: {
								font,
								callback: function(val) {
									return val;
								},
							},
							afterFit(scale) {
								scale.width = 70;
							},
						},
						physical: {
							position: 'right',
							beginAtZero: true,
							grid: {
								// only want the grid lines for one axis to show up
								drawOnChartArea: false, 
							},
							title: {
								display: true,
								text: 'Activities',
								font: fontLarge,
							},
							ticks: {
								precision: 0,
								font,
							},
							display: 'auto',
							afterFit: scale => {
								scale.width = this.shouldShowPhysicalAxes ? 70 : 0;
							},
						},
						acc: {
							position: 'right',
							beginAtZero: true,
							grid: {
								// only want the grid lines for one axis to show up
								drawOnChartArea: false, 
							},
							title: {
								display: true,
								font: fontLarge,
								text: 'Accumulated Sales',
							},
							display: 'auto',
							ticks: {
								font,
								callback: function(val) {
									return val;
								},
							},
							afterFit: (scale: any) => {
								scale.width = this.shouldShowAccumulatedSalesAxes ? 70 : 0;
							},
						},
					} satisfies Record<string, ScaleOptions<'linear'>>,
					plugins: {
						legend: {
							display: false,
						},
						tooltip: {
							callbacks: {
								title: function(titles) {
									const date = new Date(titles[0].label + '-02'); // has to be the 2nd so that local timezone can't bring it to the previous month
									return format(date, 'MMM yyyy');
								},
								label: function(context) {
									let value = context.parsed.y.toString();

									if(context.dataset.yAxisID === 'monetary' || context.dataset.yAxisID === 'acc') {
										value = '$' + context.parsed.y.toLocaleString('en-US', { useGrouping: true, minimumFractionDigits: 0, maximumFractionDigits: 0 });
									}

									return value  + ' ' + pluralize(context.dataset.label || '', context.parsed.y).toLowerCase();
								},
							},
							displayColors: false,
							titleFont: {
								...font,
								weight: '400',
							},
							bodyFont: {
								...font,
								weight: 'bold',
								size: 20,
							},
							padding: 12,
						},
					},
					animation: {
						duration: 0,
					},
				} satisfies ChartOptions,
			};
			
			if(this.activities.length <= 0) {
				return d;
			}

			// Loop through all 5 activity types and create the base dataset options and
			// year/month 0-keyed object ready for data population.
			const initialDataSet = Array.from({ length: this.labels.months.length }).fill(0) as number[]
			activityTypes.forEach(activityType => {
				const toggle = this.toggles.find(t => t.ID === activityType.ID && t.ActivityStyle === activityType.ActivityStyle);
				d.data.datasets.push({
					type: 'bar',
					label: activityType.DisplayName,
					data: [...initialDataSet],
					borderColor: activityType.GraphColor,
					backgroundColor: activityType.GraphColor,
					yAxisID: (activityType.ActivityStyle === ActivityStyle.Monetary ? 'monetary' : 'physical'),
					hidden: toggle?.hidden ?? true,
				});
			});
			
			// Loop through all activities ann add the datum to the relevant type and month key
			this.activities.forEach(activity => {
				var userTimezoneOffset = activity.CompletedDate.getTimezoneOffset() * 60000;
				var offsetDate = new Date(activity.CompletedDate.getTime() + userTimezoneOffset + (11*60*60*1000)); // add 11 hours to offset the server timezone
				const dateKey = format(offsetDate, labelDateFormat);
				const dataIndex = this.labels.months.indexOf(dateKey);
				const type = activityTypes.find(t => t.ActivityStyle === activity.ActivityType.ActivityStyle && t.ID === activity.ActivityType.ID) as ActivityType;
				const datasetIndex = activityTypes.indexOf(type);

				if(!d.data.datasets[datasetIndex].data[dataIndex]) {
					d.data.datasets[datasetIndex].data[dataIndex] = 0;
				}
				(d.data.datasets[datasetIndex].data[dataIndex] as number) += activity.NumberCompleted;
			});

			// Accumulative Sales
			// Go through the first dataset, which is sales, and accumulate them
			// through the months
			let accTotal = 0;
			const salesAcc = d.data.datasets[0].data.map(val => {
				accTotal += (val as number);
				return accTotal;
			});

			const toggle = this.toggles.find(t => t.ID === -1 && t.ActivityStyle === ActivityStyle.Monetary);
			const dataset = {
				type: 'line',
				label: 'Accumulative Sales',
				data: salesAcc,
				borderColor: '#A28B3E',
				backgroundColor: '#A28B3E',
				yAxisID: 'acc',
				order: -1,
				tension: 0.2,
				cubicInterpolationMode: 'monotone',
				hidden: toggle?.hidden ?? true,
				font: fontLarge,
			} as ChartDataset<'line'>;
			// Because we're imposing two chart types and the built in 
			// types can't handle this, lets say its a bar type, but really
			// we want to type it above as a line.
			d.data.datasets.push(dataset as ChartDataset<'bar'>);

			// Acc labels based on total acc sales
			const accLabelScale = labelScalingForMonetaryData(salesAcc);
			d.options.scales.acc!.title.text = `Accumulated Sales ${accLabelScale.label}`;
			d.options.scales.acc.ticks.callback = ((value: string) => {
				return (parseInt(value) / accLabelScale.divider).toLocaleString('en-US', { useGrouping: true, minimumFractionDigits: 0, maximumFractionDigits: 1 });
			}) as any;

			// Monetary labels based on sales & delivery
			const activeMonetaryDatas = d.data.datasets.filter((d: any) => d.yAxisID === 'monetary').reduce((acc:any, ds:any) => acc = [...acc, ...Object.values(ds.data)], []);
			const monetaryLabelScale = labelScalingForMonetaryData(activeMonetaryDatas);
			d.options.scales.monetary.title.text = `Sales & Delivery ${monetaryLabelScale.label}`;
			d.options.scales.monetary.ticks.callback = ((value: string) => {
				return (parseInt(value) / monetaryLabelScale.divider).toLocaleString('en-US', { useGrouping: true, minimumFractionDigits: 0, maximumFractionDigits: 1 });
			}) as any;
			
			return d;
		},
	},
	beforeMount() {
		this.windowBigEnough = (window.innerWidth >= 1180);
		// triggered from site-dashboard.js
		window.addEventListener('displaying-dashboard-graph', this.loadData);
	},
	unmounted() {
		window.removeEventListener('displaying-dashboard-graph', this.loadData);
	},
	methods: {
		async loadData() {
			this.loading = true;

			const { data: { activities, achievements } } = await axiosClient.post<ApiResponseActivitiesInterface>('/dashboard/get-user-activities', {
				userId: this.userId,
			});

			this.loading = false;
			this.achievements = achievements.map(a => {
				a.belt = beltLevels.find(b => b.color === a.Value) || beltLevels[0];
				return a;
			});
			this.activities = activities;
		},
		toggleDataSet(activityType: ActivityType) {
			// don't allow both sales a delivery to be unticked
			// find active monetary activities
			const activeSalesDeliveryType = this.toggles.filter(t => t.ActivityStyle === ActivityStyle.Monetary && t.ID >= 0 && !t.hidden);
			// if there is only one active, and the current one is the same, then don't allow it to be unticked
			if(activeSalesDeliveryType.length === 1 && activityType.ActivityStyle === ActivityStyle.Monetary && activeSalesDeliveryType[0].ID === activityType.ID) {
				return;
			}
			
			// Do the main activity toggle
			this.toggles.find(t => t.ActivityStyle === activityType.ActivityStyle && t.ID === activityType.ID)!.hidden = !this.toggles.find(t => t.ActivityStyle === activityType.ActivityStyle && t.ID === activityType.ID)!.hidden;

			// If some phyiscal activities and the accumulated sales are both active, we need to turn off the 
			// one that was not just toggled on
			const nowHasPhysical = this.toggles.some(t => !t.hidden && t.ActivityStyle === ActivityStyle.Physical);
			const nowHasAccSales = this.toggles.some(t => !t.hidden && t.ID === -1 && t.ActivityStyle === ActivityStyle.Monetary);
			if(nowHasPhysical && nowHasAccSales) {
				if(activityType.ActivityStyle === ActivityStyle.Physical) {
					this.toggles.find(t => t.ID === -1)!.hidden = true;
				} else {
					this.toggles
						.filter(t => t.ActivityStyle === ActivityStyle.Physical)
						.forEach(t => {
							t.hidden = true;
						});
				}
			}
		},
	},
});
