import { defineStore } from 'pinia';
import axios from 'axios';
import type { ApiResponseSpacePeople } from '@/interfaces/ApiResponseInterface';
import type { UserWithBeltAndLocation } from '@/interfaces/UserWithBeltAndLocationInterface';
import { currentSpace } from '@/data/generalData';
import { UserMarker } from '@/interfaces/UserMarkerInterface';

const storeId = 'people';
export const usePeopleStore = defineStore({
	id: storeId,
	state: () => ({
		bounds: null as google.maps.LatLngBounds | null,
		users: [] as UserWithBeltAndLocation[],
		popUpUserId: -1,
		filter: {
			name: '',
			expertise: '',
		},
		sortDirection: true,
		mapDirty: false,
		includeNoLocationUsers: false,
		infoLackingHidden: false,
	}),
	// These getters are ordered from top to bottom in order of the way they are run
	// which explains the logical flow of data 
	getters: {
		filteredNameExpertiseUsers(state): UserWithBeltAndLocation[] {
			return state.users.filter(u => {

				let shouldAllow = true;

				if(state.filter.name) {
					const name = state.filter.name.toLowerCase().trim();
					shouldAllow &&= (u.DisplayName.toLowerCase().indexOf(name) >= 0);
				}

				if(state.filter.expertise) {
					shouldAllow &&= (!!u.Expertise && u.Expertise.indexOf(state.filter.expertise) >= 0);
				}

				return shouldAllow;
			});
		},
		// All these users have location data
		filteredUsers(state): UserWithBeltAndLocation[] {
			return this.filteredNameExpertiseUsers
				.filter(u => {
					// Ensure location exists
					if(!u.Location) {
						return false;
					}

					// Location/bounds filter
					if(!state.mapDirty || !state.bounds) {
						return true;
					}

					const isInBounds = state.bounds.contains({
						lat: u.Location.Latitude, lng: u.Location.Longitude,
					});
					if (isInBounds) {
						return true;
					}

					return false;
				})
				.sort(userSortGenerator(this.sortDirection));
		},
		usersWithoutLocation(): UserWithBeltAndLocation[] {
			return this.filteredNameExpertiseUsers.filter(u => {
				return !u.Location;
			});
		},
		// We break this up here, instead of returning it directly in filteredUsers
		// because when `includeNoLocationUsers` is updated we don't want to update
		// the markers, since they can't be effected. If they are updated tho, they
		// flash on the screen. 
		listUsers(state): UserWithBeltAndLocation[] {
			if(state.includeNoLocationUsers || !state.mapDirty) {
				return [
					...this.filteredUsers,
					...this.usersWithoutLocation,
				].sort(userSortGenerator(this.sortDirection));
			}

			return this.filteredUsers;
		},
		filteredUsersMarkers(): UserMarker[] {
			return this.filteredUsers.map(u => {
				return {
					lat: u.Location.Latitude, 
					lng: u.Location.Longitude,
					userId: u.UserID,
				};
			});
		},
		popUpUser(state) {
			return state.popUpUserId === -1 ? undefined : state.users.find(u => u.UserID === state.popUpUserId);
		},
	},
	actions: {
		async loadUsersForSpace() {
			const { data } = await axios.post<ApiResponseSpacePeople>('/users/list-by-space', {
				spaceId: currentSpace.SpaceID,
			});
			this.users = data.users;
		},
		clearPopUpUser() {
			this.setPopUpUserId(-1);
		},
		setPopUpUserId(userId: number) {
			this.popUpUserId = userId;
		},
		setMapBounds(bounds: google.maps.LatLngBounds) {
			this.bounds = bounds;
		},
	},
	persist: {
		key: `tlc.vue.store.${storeId}`,
		paths: ['infoLackingHidden'],
	},
});

const userSortGenerator = function(sortDirection: boolean) {
	return function(a: UserWithBeltAndLocation, b: UserWithBeltAndLocation) {
		if(sortDirection) {
			return a.DisplayName.localeCompare(b.DisplayName);
		}
		return b.DisplayName.localeCompare(a.DisplayName);
	}
}