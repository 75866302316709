import { nextTick } from 'vue';

export const chargeebeeScript = () => {
	const cbScript = document.createElement('script');
	cbScript.setAttribute('defer', '');
	cbScript.setAttribute('async', '');
	cbScript.addEventListener('load', () => {
		if(!process.env.CHARGEBEE_SITE) {
			return;
		}
		window.Chargebee.init({
			site: process.env.CHARGEBEE_SITE,
			publishableKey: process.env.CHARGEBEE_PUBLISHABLE_KEY,
		});	

		nextTick(() => {
			window.dispatchEvent(new Event('chargebeeLoaded'));
		});
	}, false);

	cbScript.setAttribute('src', 'https://js.chargebee.com/v2/chargebee.js');
	document.body.appendChild(cbScript);
}