
import { axiosClient } from '@/utils/AxiosUtil';
import { defineComponent } from 'vue';
import { Line as LineChart } from 'vue-chartjs'
import { Chart, Tooltip, Legend, LineElement, CategoryScale, LinearScale, PointElement } from 'chart.js'
import BeltColour from '@/enums/BeltColourEnum';
import type { ApiResponseUsersByBelts } from '@/interfaces/ApiResponseInterface';
import type { UsersByBelt } from '@/interfaces/UsersByBeltInterface';

Chart.register(Tooltip, Legend, LineElement, PointElement, CategoryScale, LinearScale)

export default defineComponent({
	components: { LineChart },
	data() {
		return {
			loaded: false,
			chartData: {
				datasets: [] as any,
				labels: [] as string[],
			},
			options: {
				scales: {
					y: {
						position: 'left',
						beginAtZero: true,
					},
					totalStudentsAxis: {
						position: 'right',
						beginAtZero: true,
						grid: {
							drawOnChartArea: false, // only want the grid lines for one axis to show up
						},
					},
				},
			},
		}
	},
	beforeMount() {
		this.getUsersByBelts(6);
	},
	methods: {
		async getUsersByBelts(halfYears: number) {
			this.loaded = false;
			const { data } = await axiosClient.post<ApiResponseUsersByBelts>('/users/users-by-belts',
				{
					halfYears: halfYears,
				},
				{
					timeout: 10 * 60 * 1000,
				},
			);
			if(!data.success) {
				return window.alert('Sorry, there was an error loading the Users By Belt Report.')
			}
			
			var dates = data.usersByBelts.map(ub => ub.DateRangeEnd);

			// get the distinct dates
			dates = dates.filter((date, i, self) => 
				self.findIndex(d => d.getTime() === date.getTime()) === i,
			);
			var displayDates = dates.map(date => date.toLocaleString('default', { month: 'long' }) + ' ' + date.getFullYear());

			this.chartData = {
				labels: displayDates,
				datasets: [ 
					this.getBeltUsersForColour('Total Students', BeltColour.None, data.usersByBelts, 'rgb(255, 0, 255)', 'totalStudentsAxis'),
					this.getBeltUsersForColour('No Belt', BeltColour.SubWhite, data.usersByBelts, 'rgb(255, 165, 0)'),
					this.getBeltUsersForColour('White Belt', BeltColour.White, data.usersByBelts, 'rgb(200, 200, 200)'),
					this.getBeltUsersForColour('Yellow Belt', BeltColour.Yellow, data.usersByBelts, 'rgb(240, 240, 30)'),
					this.getBeltUsersForColour('Green Belt', BeltColour.Green, data.usersByBelts, 'rgb(0, 150, 0)'),
					this.getBeltUsersForColour('Blue Belt', BeltColour.Blue, data.usersByBelts, 'rgb(0, 0, 255)'),
					this.getBeltUsersForColour('Red Belt', BeltColour.Red, data.usersByBelts, 'rgb(255, 0, 0)'),
					this.getBeltUsersForColour('Black Belt', BeltColour.Black, data.usersByBelts, 'rgb(0, 0, 0)'),
				],
			};
			this.loaded = true;
		},
		getBeltUsersForColour(beltName: string, beltColour: BeltColour, usersByBelts: UsersByBelt[], colour: string, yAxis: string = 'y'): object {
			var usersByColour = usersByBelts.filter(ub => ub.BeltColour === beltColour);
			var beltUsers = usersByColour.map(function(ub) {
				return ub.NumberOfUsers;
			});
			return {
				label: beltName,
				data: beltUsers,
				borderColor: colour,
				backgroundColor: colour,
				yAxisID: yAxis,
			}
		},
		dataRangeChanged(event: Event) {
			var select = event.target as HTMLSelectElement;
			this.getUsersByBelts(parseInt(select.value));
		},
	},
});
