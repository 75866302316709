
// This component gets all users of the current space, with some extended profile data
// and most importantly their location data. Based on where the map is centered, its 'bounds'
// and a name and expertise filter, we show users clustered on the map, with the ability
// to click to open a model with more info about them.

import { defineComponent } from 'vue';
import { mapActions, mapState, mapWritableState } from 'pinia';
import * as pluralize from 'pluralize';
import { GoogleMap, Marker as MapMarker, MarkerCluster } from 'vue3-google-map';
import type { MarkerClustererOptions } from '@googlemaps/markerclusterer';
import { Vue3ToggleButton } from 'vue3-toggle-button';
import { usePeopleStore } from '@/stores/peopleStore';
import { expertiseData } from '@/data/expertiseData';
import { currentUser } from '@/data/currentUserData';
import { currentSpace } from '@/data/generalData';
import SpacePerson from '@/components/SpacePersonComponent.vue';
import PersonAvatar from '@/components/PersonAvatarComponent.vue';
import mapPin from '@/images/icons/map-pin.svg';
import { UserWithBeltAndLocation } from '@/interfaces/UserWithBeltAndLocationInterface';
import { debounce } from '@/utils/GeneralUtils';
const mapDefaults = {
	center: { lat: 0, lng: 133.7751 },
	zoom: 1,
};

export default defineComponent({
	components: {
		SpacePerson,
		PersonAvatar,
		GoogleMap,
		MapMarker,
		MarkerCluster,
		Vue3ToggleButton,
	},
	data() {
		return {
			googleLoaded: false,
			dataLoaded: false,
			expertiseData,
			locationFilter: {} as HTMLInputElement,
			searchBox: {} as google.maps.places.SearchBox,
			api: {} as typeof google.maps,
			map: {} as google.maps.Map,
			currentUser,
			apiKey: process.env.GOOGLE_MAPS,
			mapStyle: [
				{
					'featureType': 'water',
					'elementType': 'labels',
					'stylers': [
						{
							'hue': '#000000',
						},
						{
							'saturation': -100,
						},
						{
							'lightness': -100,
						},
						{
							'visibility': 'off',
						},
					],
				},
				{
					'featureType': 'road.local',
					'elementType': 'all',
					'stylers': [
						{
							'hue': '#ffffff',
						},
						{
							'saturation': -100,
						},
						{
							'lightness': 100,
						},
						{
							'visibility': 'on',
						},
					],
				},
				{
					'featureType': 'water',
					'elementType': 'geometry.fill',
					'stylers': [
						{
							'visibility': 'on',
						},
						{
							'color': '#999999',
						},
					],
				},
				{
					'featureType': 'transit',
					'elementType': 'labels',
					'stylers': [
						{
							'hue': '#000000',
						},
						{
							'saturation': 0,
						},
						{
							'lightness': -100,
						},
						{
							'visibility': 'off',
						},
					],
				},
				{
					'featureType': 'landscape',
					'elementType': 'labels',
					'stylers': [
						{
							'hue': '#000000',
						},
						{
							'saturation': -100,
						},
						{
							'lightness': -100,
						},
						{
							'visibility': 'off',
						},
					],
				},
				{
					'featureType': 'road',
					'elementType': 'geometry',
					'stylers': [
						{
							'hue': '#bbbbbb',
						},
						{
							'saturation': -100,
						},
						{
							'lightness': 26,
						},
						{
							'visibility': 'on',
						},
					],
				},
				{
					'featureType': 'landscape',
					'elementType': 'geometry',
					'stylers': [
						{
							'hue': '#dddddd',
						},
						{
							'saturation': -100,
						},
						{
							'lightness': -3,
						},
						{
							'visibility': 'on',
						},
					],
				},
				{
					'featureType': 'road.arterial',
					'elementType': 'labels',
					'stylers': [
						{
							'visibility': 'off',
						},
					],
				},
				{
					'featureType': 'road.highway',
					'elementType': 'labels',
					'stylers': [
						{
							'visibility': 'off',
						},
					],
				},
				{
					'featureType': 'road.local',
					'elementType': 'labels',
					'stylers': [
						{
							'visibility': 'off',
						},
					],
				},
				{
					'featureType': 'transit.station.bus',
					'elementType': 'labels',
					'stylers': [
						{
							'visibility': 'off',
						},
					],
				},
			],
			...mapDefaults,
			currentSpace,
		}
	},
	computed: {
		locationPlaceholder(): string {
			return this.mapDirty ? 'Map area' : 'Location';
		},
		clusterOptions(): MarkerClustererOptions | undefined {
			if(!this.googleLoaded) {
				return;
			}

			return {
				renderer: {
					render: ({ count, position }) => {
						return new this.api.Marker(this.markerOptionsGenerator(count, position));
					},
				},

			};
		},
		infoLacking(): string[] {
			const res = [];
			if(!this.currentUser.Expertise) {
				res.push('expertise');
			}
			
			if (!this.currentUser.Location?.Longitude || !this.currentUser.Location?.Latitude) {
				res.push('location');
			}

			return res;
		},
		...mapState(usePeopleStore, ['popUpUser', 'filteredUsersMarkers', 'usersWithoutLocation', 'listUsers']),
		...mapWritableState(usePeopleStore, ['filter', 'sortDirection', 'mapDirty', 'includeNoLocationUsers', 'infoLackingHidden']),
	},
	async beforeMount() {
		const store = usePeopleStore();
		await store.loadUsersForSpace();
		this.dataLoaded = true;
	},
	mounted() {
		this.$watch(
			() => {
				return (this.$refs.mapRef as any).ready;
			},
			(val: any) => {
				if(val) {
					if(this.googleLoaded) {
						return;
					}

					this.api = (this.$refs.mapRef as any).api;
					this.map = (this.$refs.mapRef as any).map;

					this.init();

					this.googleLoaded = true;
				}
			},
		);
	},
	methods: {
		init() {
			// Create the search box and link it to the UI element
			this.locationFilter = this.$refs.locationFilter as HTMLInputElement;
			this.searchBox = new this.api.places.SearchBox(this.locationFilter);

			const debouncedSet = debounce((bounds: google.maps.LatLngBounds) => {
				this.setMapBounds(bounds);
			}, 200);

			// Push the bounds to the store to recalculate filtered users
			// Bias the SearchBox results towards current map's viewport.
			this.map.addListener('bounds_changed', () => {
				const bounds = this.map.getBounds();
				if(!bounds) {
					return;
				}
				this.searchBox.setBounds(bounds);
				debouncedSet(bounds);
			});

			// If a user drags the map, reset the location filter
			// since we're not over the same location anymore. We 
			// don't need to watch for the zoom_changed event because 
			// they would still be centered on the location that they
			// searched for initially.
			this.map.addListener('dragend', () => {
				this.resetLocationFilter();
				this.mapDirty = true;
			});

			this.map.addListener('zoom_changed', () => {
				this.mapDirty = true;
			});

			// When a user searchs for a location and we find some, loop through 
			// them and inclde/extend a bounds to include them all and then set
			// the map bounds to this
			this.searchBox.addListener('places_changed', () => {
				this.mapDirty = true;
				
				const places = this.searchBox.getPlaces();
				if (places == null || places.length === 0) {
					return;
				}

				const bounds = new this.api.LatLngBounds();
				let wantHigherZoom = false;

				places.forEach(place => {
					if (!place.geometry || !place.geometry.location) {
						return;
					}

					// Override the zoom when searching for these specific places,
					// looks liuke Google does not do a good job of it... For instance
					// searching for New Zealand shows all of Australia too...
					if(place.formatted_address && [
						'New Zealand',
						'Australia',
					].indexOf(place.formatted_address) >= 0) {
						wantHigherZoom = true;
					}

					if (place.geometry.viewport) {
						// Only geocodes have viewport.
						bounds.union(place.geometry.viewport);
					} else {
						bounds.extend(place.geometry.location);
					}
				});

				this.map.fitBounds(bounds);
				if(wantHigherZoom) {
					this.map.setZoom(4);
				}
			});
		},
		sendMessage(userId: number) {
			const messageComposeModal = window.Communifire.Modals.MessageCompose;
			messageComposeModal.init({ messageComposeURL: '/myaccount/ajaxtemplates/messagecompose' });
			messageComposeModal.open(userId);
			this.clearPopUpUser();
		},
		resetFilters() {
			this.filter.expertise = '';
			this.filter.name = '';
			this.resetLocationFilter();

			this.map.setCenter(mapDefaults.center);
			this.map.setZoom(mapDefaults.zoom);

			// Need to set this at the bottom because calling zoom triggers
			// the zoom event which we watch, and that sets dirty to true.
			this.mapDirty = false;
		},
		resetLocationFilter() {
			this.locationFilter.value = '';
			this.searchBox.set('place', null);
		},
		centerOnUser(user: UserWithBeltAndLocation) {
			document.getElementById('vue-people')?.scrollIntoView({
				behavior: 'smooth',
			});
			this.map.setCenter({
				lat: user.Location.Latitude,
				lng: user.Location.Longitude,
			});
			this.map.setZoom(8);
		},
		markerOptionsGenerator(count: number, position: google.maps.LatLng | google.maps.LatLngLiteral): google.maps.MarkerOptions {
			const text = count.toString();
			const fontSize = text.length >= 3 ? '14px' : '18px';

			return {
				label: { text, color: 'white', fontSize, fontWeight: 'bold', fontFamily: `'Poppins', sans-serif`, className: 'marker-label' },
				position,
				icon: {
					url: mapPin,
					scaledSize: (this.googleLoaded ? new this.api.Size(31, 41) : undefined),
				},
			};
		},
		pluralize,
		...mapActions(usePeopleStore, ['loadUsersForSpace', 'clearPopUpUser', 'setPopUpUserId', 'setMapBounds']),
	},
})
