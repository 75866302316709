
import { defineComponent } from 'vue';
import type { PropType } from 'vue';
import { mapActions } from 'pinia';
import { useAnnouncementsStore } from '@/stores/announcementStore';
import type { Announcement } from '@/interfaces/AnnouncementInterface';

export default defineComponent({
	props: {
		announcement: {
			type: Object as PropType<Announcement>,
			default: {} as Announcement,
		},
	},
	methods: {
		...mapActions(useAnnouncementsStore, ['hideAnnouncement']),
	},
})
