import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-75714b21"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id", "data-wiki-id"]
const _hoisted_2 = { class: "wiki-title" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = {
  key: 1,
  class: "lesson-heading"
}
const _hoisted_5 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_wiki_tree_item_component = _resolveComponent("wiki-tree-item-component", true)!

  return (_openBlock(), _createElementBlock("div", {
    id: (_ctx.isRoot ? 'pages': ''),
    class: _normalizeClass(["wiki-tree-item", {
			'current': _ctx.isCurrent, 
			'current-child': _ctx.hasActiveChild, 
			'open': _ctx.open,
		}]),
    "data-wiki-id": _ctx.wiki.ContentID
  }, [
    _createElementVNode("div", _hoisted_2, [
      (!_ctx.isRoot && _ctx.wiki.children)
        ? (_openBlock(), _createElementBlock("i", {
            key: 0,
            class: "icon-caret-right",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.clickedOpen = !_ctx.clickedOpen))
          }))
        : _createCommentVNode("v-if", true),
      _createVNode(_component_router_link, {
        to: { name: 'wikiView', params: { id: _ctx.wiki.ContentID, slug: _ctx.wiki.ContentStub }}
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", {
            innerHTML: _ctx.wiki.ContentTitle
          }, null, 8 /* PROPS */, _hoisted_3),
          (_ctx.result)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(["lesson-status", _ctx.result])
              }, null, 2 /* CLASS */))
            : _createCommentVNode("v-if", true),
          (_ctx.isRoot)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, " TEST "))
            : _createCommentVNode("v-if", true)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["to"])
    ]),
    (_ctx.wiki.children)
      ? (_openBlock(), _createElementBlock("ul", _hoisted_5, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.wiki.children, (w) => {
            return (_openBlock(), _createElementBlock("li", {
              key: w.ContentID
            }, [
              _createVNode(_component_wiki_tree_item_component, { wiki: w }, null, 8 /* PROPS */, ["wiki"])
            ]))
          }), 128 /* KEYED_FRAGMENT */))
        ]))
      : _createCommentVNode("v-if", true)
  ], 10 /* CLASS, PROPS */, _hoisted_1))
}