
import { addDays, format } from 'date-fns';
import { defineComponent } from 'vue';
import { upcomingImmersionDates } from '@/utils/GeneralUtils';

export default defineComponent({
	props: {
		count: {
			type: Number,
			default: 4,
		},
	},
	computed: {
		upcomingImmersionDatesDisplay() {
			return upcomingImmersionDates().map(d => {
				const endDate = addDays(d, 1);
				// These dates should never wrap around a month, so we don't need to 
				// worry about showing two different months.
				return format(d, 'MMM d') + '-' + format(endDate, 'd, yyyy'); 
			});
		},
		upcomingImmersionDatesYears() {
			const dates = upcomingImmersionDates();
			const firstYear = format(dates[0], 'yyyy');
			const lastYear = format(dates[dates.length - 1], 'yyyy');
			return firstYear + ' - ' + lastYear;
		},
	},
});
