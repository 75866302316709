import { defineStore } from 'pinia';
import axios from 'axios';

import type { Announcement } from '@/interfaces/AnnouncementInterface';
import { currentUser } from '@/data/currentUserData';
import { currentSpace } from '@/data/generalData';

export const useAnnouncementsStore = defineStore({
	id: 'announcement',
	state: () => ({
		announcements: [] as Announcement[],
	}),
	getters: {
		siteWideAnnouncements(state) {
			return state.announcements.filter(a => a.Space == null);
		},
		locationAnnouncements(state) {
			const nonSiteWide = state.announcements.filter(a => a.Space != null);
			
			if(currentSpace) {
				return nonSiteWide.filter(a => a.Space.SpaceID === currentSpace.SpaceID);
			}
            
			return nonSiteWide.filter(a => currentUser.UserSpaceIds.some(usi => usi === a.Space.SpaceID));
		},
	}, 
	actions: {
		hideAnnouncement(announcement: Announcement) {
			const stateAnnouncement = this.announcements.find(a => a.AnnouncementID === announcement.AnnouncementID);
			if(stateAnnouncement == null) {
				return;
			}
            
			stateAnnouncement.isHiding = true;

			axios.post('/announcement/hide', {
				userId: currentUser.UserID,
				announcementId: announcement.AnnouncementID,
			});
		},
		setAnnouncements(announcements: Announcement[]) {
			this.announcements = [...announcements];
		},
	},
})