import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-59da3e17"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tl-tooltip-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "tl-tooltip-trigger",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.open = !_ctx.open))
    }, [
      _renderSlot(_ctx.$slots, "trigger", {}, undefined, true)
    ]),
    (_ctx.open)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "tl-tooltip",
          onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"]))
        }, [
          _renderSlot(_ctx.$slots, "content", {}, undefined, true)
        ]))
      : _createCommentVNode("v-if", true)
  ]))
}