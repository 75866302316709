import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("li", {
    role: "button",
    class: _normalizeClass(["sidebar-collapser", {
			'is-sidebar-collapsed': _ctx.isSidebarCollapsed,
		}]),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleSidebar && _ctx.toggleSidebar(...args)))
  }, null, 2 /* CLASS */))
}