
// This component could potentially be merged with SpaceTagFilter.
// 
// While its possible to look for the click events on the tags and stop the propagation of the event
// so that multiples are not added to the tag list, its not as easy to watch for when tags are removed
// from the event list, and will tie the component very closely to the HTML and CF code. So instead, 
// we keep with existing CF functionality where you can add a tag multiple times to the tag list, 
// but of course will only save a single instance of that tag to the content on save.

import { defineComponent } from 'vue';
import type { PropType } from 'vue';
import type { TagGroup } from '@/interfaces/TagGroupInterface';

export default defineComponent({
	props: {
		initialTagGroups: {
			type: Array as PropType<Array<TagGroup>>,
			default: [] as Array<TagGroup>,
		},
	},
	data: function () {
		return {
			tagGroups: [] as Array<TagGroup>,
		}
	},
	created: function () {
		// Add class to allow us to hide the original only if this component loads
		document.body.classList.add('vue-space-add-edit-tag-list');
		const $label = document.querySelector('label[id*="AddTagsControl_TagLabel"]');
		if($label) {
			$label.innerHTML = 'Selected Tags';
		}

		this.tagGroups = [...this.initialTagGroups];
	},
});
