
import { defineComponent } from 'vue';
import { format } from 'date-fns';
import { VueSignaturePad } from 'vue-signature-pad';
const date = new Date();

export default defineComponent({
	components: { VueSignaturePad },
	emits: ['change'], 
	computed: {
		signaturePad(): VueSignaturePad {
			return (this.$refs.signaturePad as VueSignaturePad);
		},
		dateDay(): string {
			return format(date, 'yyyy-MM-dd');
		},
	},
	methods: {
		onEnd() {
			this.hasChanged();
		},
		undo() {
			this.signaturePad.undoSignature();
			this.hasChanged();
		},
		clear() {
			this.signaturePad.clearSignature();
			this.hasChanged();
		},
		hasChanged() {
			const signature = this.signaturePad.saveSignature();
			this.$emit('change', signature.isEmpty ? '' : signature.data);
		},
	},
});
