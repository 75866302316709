import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ToolTipComponent = _resolveComponent("ToolTipComponent")!

  return (_openBlock(), _createBlock(_component_ToolTipComponent, null, {
    trigger: _withCtx(() => [
      _createElementVNode("div", { innerHTML: _ctx.trigger }, null, 8 /* PROPS */, _hoisted_1)
    ]),
    content: _withCtx(() => [
      _createElementVNode("div", { innerHTML: _ctx.content }, null, 8 /* PROPS */, _hoisted_2)
    ]),
    _: 1 /* STABLE */
  }))
}