
import { ModalButtonInterface } from '@/interfaces/ModalButtonInterface';
import { defineComponent } from 'vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import LoadingComponent from '@/components/presentation/LoadingComponent.vue';

export default defineComponent({
	components: { ButtonComponent, LoadingComponent },
	props: {
		buttons: {
			type: Array<ModalButtonInterface>,
			default: [] as Array<ModalButtonInterface>,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		hideHeader: {
			type: Boolean,
			default: false,
		},
		hideFooter: {
			type: Boolean,
			default: false,
		},
		hideClose: {
			type: Boolean,
			default: false,
		},
	},
	emits: ['close'],
	data() {
		return {
			closeButton: {
				title: 'Close',
				fn: () => {
					this.close();
				},
			},
		};
	},
	methods: {
		close() {
			if(this.loading) {
				return;
			}

			this.$emit('close');
		},
	},
})
