
import { defineComponent } from 'vue';

export default defineComponent({
	data() {
		return {
			open: false,
		};
	},
	mounted() {
		document.addEventListener('keydown', this.keyPressed);
		document.addEventListener('click', this.closeTooltip);
		var myAccountContainer = document.getElementById('axero-my-account-container');
		if (myAccountContainer) {
			myAccountContainer.style.overflow = 'visible';
		}
	},
	beforeUnmount() {
		document.removeEventListener('keydown', this.keyPressed);
		document.removeEventListener('click', this.closeTooltip);
		var myAccountContainer = document.getElementById('axero-my-account-container');
		if (myAccountContainer) {
			myAccountContainer.style.overflow = '';
		}
	},
	methods: {
		closeTooltip(event: any) {
			if (event.target.closest('.tl-tooltip-container') !== this.$el) {
				this.open = false;
			}
		},
		keyPressed(event: any) {
			// escape key
			if (event.keyCode === 27) {
				this.open = false;
			}
		},
	},
})
