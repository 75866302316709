
import { defineComponent } from 'vue';
import { formatCents } from '@/utils/GeneralUtils';

export default defineComponent({
	props: {
		cents: {
			type: Number,
			default: 0,
		},
		isTaxable: {
			type: Boolean,
			default: true,
		},
	},
	methods: {
		formatCents,
	},
});
