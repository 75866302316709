
import { defineComponent } from 'vue';
import { mapActions, mapState } from 'pinia';
import { format } from 'date-fns';
import * as pluralize from 'pluralize';

import { useBillingStore } from '@/stores/billingStore';
import { formatCents, toKebabCase } from '@/utils/GeneralUtils';
import downloadIcon from '@/images/icons/icon-download.svg?inline';
import LoadingComponent from './presentation/LoadingComponent.vue';
import ToolTipComponent from './ToolTipComponent.vue';
import CardChooserComponent from './CardChooserComponent.vue';
import type { ChargebeeCard } from '@/interfaces/ChargebeeCardInterface';

export default defineComponent({
	components: { downloadIcon, LoadingComponent, ToolTipComponent, CardChooserComponent },
	props: {
		userId: {
			type: Number,
			default: null,
		},
	},
	data() {
		return {
			showAllInvoices: false,
		}
	},
	computed: {
		shownInvoices() {
			return this.showAllInvoices ? this.invoices : this.invoices.slice(0, 5);
		},
		...mapState(useBillingStore, ['loading', 'invoices', 'activeAndFutureSubscriptions']),
	},
	beforeMount() {
		this.loadBilling(this.userId);
	},
	methods: {
		displayDate(date: Date): string {
			return format(date, 'PP');
		},
		subscriptionCardChanged(selectedCard: ChargebeeCard, subscriptionId: string) {
			var subscription = this.activeAndFutureSubscriptions.find(s => s.Id === subscriptionId);
			if (subscription) {
				subscription.PaidWithCardLastFour = selectedCard.Last4;
			}
		},
		formatCents,
		toKebabCase,
		pluralize,
		...mapActions(useBillingStore, ['loadBilling']),
	},
});
