// This enum should be kept in sync with it's C# counterpart
// app/Thoughtleaders/Enumerations/ChargebeePlan.cs

enum ChargebeePlanIdEnum {
	DEBUGTestPlan = 'test-$1-once-off',
	BlackBeltRetreat2023 = 'black-belt-retreat-2023',
	BusinessSchool1000Deposit = 'business-school-1000-deposit',
	BusinessSchool1500Deposit = 'the-school-1500-deposit',
	PayInFull10kOffer = 'pay-in-full-10k-offer',
	PartPay10kOffer = 'part-pay-10k-offer',
	BusinessSchoolOfferAlumni5k = 'business-school-offer-alumni-5k',
	Fiji2023TlbsUpfront = 'fiji-2023-tlbs-upfront',
	Fiji2023TlbsPartPay = 'fiji-2023-tlbs-partpay',
	Fiji2023BbUpfront = 'fiji-2023-bb-upfront',
	Fiji2023BbPartPay = 'fiji-2023-bb-partpay',
}

export { ChargebeePlanIdEnum };