import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d4b7d406"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "vue-tl-signature-component" }
const _hoisted_2 = { class: "signature" }
const _hoisted_3 = { class: "signature-container" }
const _hoisted_4 = { class: "signature-buttons" }
const _hoisted_5 = { class: "signed" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_signature_pad = _resolveComponent("vue-signature-pad")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_vue_signature_pad, {
          ref: "signaturePad",
          width: "300px",
          height: "100px",
          options: { onEnd: _ctx.onEnd, penColor: 'rgb(85 85 85)' }
        }, null, 8 /* PROPS */, ["options"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", {
          role: "button",
          title: "Undo",
          onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.undo && _ctx.undo(...args)), ["prevent"]))
        }, " ⎌ "),
        _createElementVNode("div", {
          role: "button",
          title: "Reset",
          onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.clear && _ctx.clear(...args)), ["prevent"]))
        }, " × ")
      ]),
      _createElementVNode("div", _hoisted_5, " Signed: " + _toDisplayString(_ctx.dateDay), 1 /* TEXT */)
    ])
  ]))
}