import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2df5a63c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tl-vue-component-calendar-add" }
const _hoisted_2 = ["href", "title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.links, (link) => {
      return (_openBlock(), _createElementBlock("li", {
        key: link.name
      }, [
        _createElementVNode("a", {
          href: link.link,
          target: "_blank",
          title: link.name
        }, [
          (_openBlock(), _createBlock(_resolveDynamicComponent(link.icon)))
        ], 8 /* PROPS */, _hoisted_2)
      ]))
    }), 128 /* KEYED_FRAGMENT */))
  ]))
}