
import { defineComponent } from 'vue';
import { mapState } from 'pinia';
import { useAnnouncementsStore } from '@/stores/announcementStore';
import AnnouncementSingle from '@/components/AnnouncementSingleComponent.vue'

export default defineComponent({
	components: {
		AnnouncementSingle,
	},
	props: {
		type: {
			type: String,
			required: true,
		},
	},
	computed: {
		...mapState(useAnnouncementsStore, ['siteWideAnnouncements', 'locationAnnouncements']),
		announcements() {
			return this.type === 'site-wide' ? this.siteWideAnnouncements : this.locationAnnouncements;
		},
		hasAnyVisibleAnnouncements() {
			return this.announcements.some(a => !a.isHiding);
		},
	},
})
