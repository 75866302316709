
import { defineComponent } from 'vue';
import { axiosClient } from '@/utils/AxiosUtil';
import type { ApiResponse } from '@/interfaces/ApiResponseInterface';
import type { ApiResponseUserBeltAchievements } from '@/interfaces/ApiResponseInterface';
import type { UserBeltAchievement } from '@/interfaces/UserBeltAchievementInterface';

export default defineComponent({
	data() {
		return {
			userBeltAchievements: [] as UserBeltAchievement[],
		}
	},
	beforeMount() {
		this.getUserBeltAchievements();
	},
	methods: {
		async getUserBeltAchievements() {
			const { data } = await axiosClient.post<ApiResponseUserBeltAchievements>('/tl-admin/admintools/get-belt-achievements-in-queue');
			if(!data.success) {
				return window.alert('Sorry, there was an error loading the user belts in the queue.')
			}
			this.userBeltAchievements = data.userBeltAchievements;
		},
		async completeBeltAchievement(userId: number) {
			const { data } = await axiosClient.post<ApiResponse>('/tl-admin/admintools/complete-belt-achievement-in-queue', {
				userId,
			});
			if(!data.success) {
				return window.alert('Sorry, there was an error completing the belt achievement.')
			}
			this.userBeltAchievements = this.userBeltAchievements.filter(ub => {
				return ub.UserID !== userId;
			});
		},
		async cancelBeltAchievement(userId: number) {
			const { data } = await axiosClient.post<ApiResponse>('/tl-admin/admintools/cancel-belt-achievement-in-queue', {
				userId,
			});
			if(!data.success) {
				return window.alert('Sorry, there was an error cancelling the belt achievement.')
			}
			this.userBeltAchievements = this.userBeltAchievements.filter(ub => {
				return ub.UserID !== userId;
			});
		},
	},
});
