
import { defineComponent } from 'vue';
import { mapActions, mapState } from 'pinia';
import { Editor, EditorContent } from '@tiptap/vue-3';
import { Link } from '@tiptap/extension-link';
import StarterKit from '@tiptap/starter-kit';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
    
import { useAdminAnnouncementsStore } from '@/stores/adminAnnouncementStore';
import type { Announcement } from '@/interfaces/AnnouncementInterface';

export default defineComponent({
	components: {
		EditorContent,
		Datepicker,
	},
	data() {
		return {
			isAddEditing: false,
			newAnnouncement: {
				Space: {},
			} as Announcement,
			editor: {} as Editor as any,
		}
	},
	computed: {
		...mapState(useAdminAnnouncementsStore, ['announcements', 'innactiveAnnouncementsLoaded', 'spaces', 'loaded']),
	},
	mounted() {
		this.loadSpaces();
		this.loadAnnouncements(false);
	},
	methods: {
		setLink() {
			const previousUrl = this.editor.getAttributes('link').href
			const url = window.prompt('URL', previousUrl)

			// cancelled
			if (url === null) {
				return
			}

			// empty
			if (url === '') {
				this.editor
					.chain()
					.focus()
					.extendMarkRange('link')
					.unsetLink()
					.run()

				return
			}

			// update link
			this.editor
				.chain()
				.focus()
				.extendMarkRange('link')
				.setLink({ href: url, target: '_blank' })
				.run()
		},
		startAdding() {
			this.isAddEditing = true;

			this.editor = new Editor({
				extensions: [
					StarterKit,
					Link.configure({
						openOnClick: false,
					}),
				],
				editorProps: {
					attributes: {
						class: 'prose-mirror',
					},
				},
			});
		},
		async validateAndAdd() {
			this.newAnnouncement.Copy = this.editor.getHTML();

			// spaceId can be null, that is site wide
			if (!this.newAnnouncement.Copy || !this.newAnnouncement.DateEnding) {
				return false;
			}

			await this.add(this.newAnnouncement);
			
			this.editor.destroy()
			this.isAddEditing = false;
			this.newAnnouncement = {
				Space: {},
			} as Announcement;
		},
		...mapActions(useAdminAnnouncementsStore, ['loadAnnouncements', 'remove', 'loadSpaces', 'add']),
	},
})
