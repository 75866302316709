import { defineStore } from 'pinia';
import { axiosClient } from '@/utils/AxiosUtil';
import type { ChargebeeCard } from '@/interfaces/ChargebeeCardInterface';
import type { ApiResponse, ApiResponseCardsInterface, ApiResponseChargebeeCardAdd } from '@/interfaces/ApiResponseInterface';
import { currentUser } from '@/data/currentUserData';

export const usePaymentStore = defineStore({
	id: 'payment',
	state: () => {
		return ({
			loaded: false,
			loading: false,
			cards: [] as ChargebeeCard[],
			cardChooser: {
				choosingDifferentCard: false,
				modalOpen: false,
			},
		});
	},
	getters: {
		primaryCard(state): ChargebeeCard {
			return state.cards.find(c => c.IsPrimary) || this.cards[0];
		},
		hasValidCard(state): boolean {
			return state.cards.length > 0;
		},
	},
	actions: {
		async loadCards() {
			if(this.loaded || this.loading) {
				return;
			}

			if(!currentUser.UserID) {
				return;
			}

			this.loading = true;

			const { data } = await axiosClient.post<ApiResponseCardsInterface>('/chargebee/get-user-cards');
			this.cards = data.cards;

			this.loaded = true;
			this.loading = false;
		},
		async addCard(token: string): Promise<ChargebeeCard> {
			this.loading = true;
			const { data } = await axiosClient.post<ApiResponseChargebeeCardAdd>('/login/public/chargebee/add-card-to-customer', {
				token,
				user: currentUser.UserID ? null : currentUser,
			});

			if(!data.success) {
				window.alert('Sorry, there was an error adding your card. Please contact us (finance@thoughtleaders.com.au, or via TLC) to purchase your ticket.')
			}
			
			this.closeModalToAddCard();
			this.cards.unshift(data.card);
			this.loading = false;
			return data.card;
		},
		async deleteCard(card: ChargebeeCard) {
			if(!window.confirm('Are you sure you want to remove this card from your account? If this card is used for ongoing payments, your next available card will be used instead.')) {
				return;
			}
			this.loading = true;
			const { data } = await axiosClient.post<ApiResponse>('/chargebee/card-delete', {
				paymentSourceId: card.Id,
			});

			if(!data.success) {
				return window.alert('Sorry, there was an error removing your card. Please refresh the page and try again. Otherwise contact us at finance@thoughtleaders.com.au, or via TLC.');
			}

			this.cards = this.cards.filter(c => c.Id !== card.Id);

			// If we've removed the only active card, set the first card as active but 
			// don't trigger the card chooser to close
			if(!this.cards.some(c => c.IsPrimary)) {
				this.cards[0].IsPrimary = true;
			}
			this.loading = false;
		},
		openModalToAddCard() {
			this.cardChooser.modalOpen = true;
			this.cardChooser.choosingDifferentCard = false;
		},
		closeModalToAddCard() {
			this.cardChooser.modalOpen = false;
		},
		toggleChoosingDifferentCard() {
			this.cardChooser.choosingDifferentCard = !this.cardChooser.choosingDifferentCard;
		},
	},
});
