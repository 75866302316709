
import { defineComponent } from 'vue';
import { axiosClient } from '@/utils/AxiosUtil';
import { ApiResponse, ApiResponseEvents } from '@/interfaces/ApiResponseInterface';
import type { Event } from '@/interfaces/EventInterface';

export default defineComponent({
	data() {
		return {
			eventFile: {} as File,
			events: {} as Event[],
			googleSignInLink: '',
			isSignedInToGoogle: false,
		}
	},
	beforeMount() {
		this.signInToGoogle();
	},
	methods: {
		async onFileChanged(event: any) {
			if (event.target.files.length > 1) {
				return alert('Please only select one csv file.');
			}
			var file = event.target.files[0];
			var formData = new FormData();
			formData.append('filename', file.name);
			formData.append('file', file);

			var { data } = await axiosClient.post<ApiResponseEvents>('/csvimporter/upload-csv-file', formData);
			if(!data.success) {
				return window.alert(data.message)
			}
			this.events = data.events;
		},
		async addEvents() {
			if (!(this.events && this.events.length > 0)) {
				return;
			}
			if(!window.confirm('Are you sure you want to upload these events?')) {
				return;
			}
			var { data } = await axiosClient.post<ApiResponse>('/csvimporter/add-events', {
				events: this.events,
			});
			if(!data.success) {
				return window.alert('Error encountered when trying to add the events.')
			}
			return window.alert('Events were added successfully to TLC.')
		},
		async signInToGoogle() {
			var { data } = await axiosClient.post<ApiResponse>('/csvimporter/get-google-auth-url');
			if(!data.success) {
				return window.alert('Error encountered when retrieving the google sign in URL.')
			}
			this.googleSignInLink = data.message;
		},
		async createGoogleEvents() {
			if (!(this.events && this.events.length > 0)) {
				return;
			}
			if(!window.confirm('Are you sure you want to create Google calendar events?')) {
				return;
			}
			var { data } = await axiosClient.post<ApiResponse>('/csvimporter/create-google-calendar-events', {
				events: this.events,
			});
			if(!data.success) {
				return window.alert('Error encountered when trying to create the Google calendar events.')
			}
			return window.alert('Google calendar events were created successfully.')
		},
	},
});
