import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2b492c4e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "content-header"
}
const _hoisted_2 = { class: "content-body" }
const _hoisted_3 = {
  key: 2,
  class: "content-footer"
}
const _hoisted_4 = { class: "buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ButtonComponent = _resolveComponent("ButtonComponent")!
  const _component_LoadingComponent = _resolveComponent("LoadingComponent")!

  return (_openBlock(), _createElementBlock("div", {
    class: "vue-tl-modal-component",
    onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
  }, [
    _createElementVNode("div", {
      class: "content-inner",
      onClick: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["stop","prevent"]))
    }, [
      (!_ctx.hideClose)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "tl-vue-close",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
          }, " × "))
        : _createCommentVNode("v-if", true),
      (!_ctx.hideHeader)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _renderSlot(_ctx.$slots, "header", {}, undefined, true)
          ]))
        : _createCommentVNode("v-if", true),
      _createElementVNode("div", _hoisted_2, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ]),
      (!_ctx.hideFooter)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.buttons, (button, i) => {
                return (_openBlock(), _createBlock(_component_ButtonComponent, {
                  key: i,
                  disabled: button.disabled,
                  onClick: _withModifiers(($event: any) => (button.fn()), ["prevent"])
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(button.title), 1 /* TEXT */)
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["disabled", "onClick"]))
              }), 128 /* KEYED_FRAGMENT */)),
              _createVNode(_component_ButtonComponent, {
                secondary: true,
                onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.closeButton.fn()), ["prevent"]))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.closeButton.title), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              })
            ])
          ]))
        : _createCommentVNode("v-if", true),
      _createVNode(_component_LoadingComponent, { loading: _ctx.loading }, null, 8 /* PROPS */, ["loading"])
    ])
  ]))
}