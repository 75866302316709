
import { defineComponent } from 'vue';
import { axiosClient } from '@/utils/AxiosUtil';
import type { ApiResponseSelectableUsers } from '@/interfaces/ApiResponseInterface';
import type { ApiResponseMigrateUsers } from '@/interfaces/ApiResponseInterface';
import type { SelectableUser } from '@/interfaces/SelectableUserInterface';


export default defineComponent({
	data() {
		return {
			emailFilter: '',
			nameFilter: '',
			users: [] as SelectableUser[],
			loading: false,
		}
	},
	computed: {
		usersFiltered: function() {
			if (!this.emailFilter && !this.nameFilter) {
				return this.users;
			}

			return this.users.filter(user => {
				if (this.emailFilter && user.Email.toLowerCase().indexOf(this.emailFilter.toLowerCase()) >= 0) {
					return true;
				}

				if (this.nameFilter && user.DisplayName.toLowerCase().indexOf(this.nameFilter.toLowerCase()) >= 0) {
					return true;
				}

				return false;
			});
		},
		chosenUsers: function () {
			return this.users.filter(function(user) {
				return user.IsSelected;
			});
		},
		canSubmit: function() {
			return this.chosenUsers.length > 0;
		},
	},
	methods: {
		async loadUsers() {
			this.loading = true;

			const { data } = await axiosClient.post<ApiResponseSelectableUsers>('/tl-admin/admintools/getfpusers');

			this.users = data.users;
			this.loading = false;
		},
		async migrateUsers(chosenUsers: SelectableUser[]) {
			if (!confirm('This will migrate ' + (chosenUsers.length) + ' users. Are you sure you want to continue?')) {
				return;
			}
			const { data } = await axiosClient.post<ApiResponseMigrateUsers>('/tl-admin/admintools/fp-to-bs-migrate-users', {
				userIds: chosenUsers.map(function(user) {
					return user.UserID;
				}),
			});

			if (data.success) {
				alert(chosenUsers.length +
					' users and ' +
					data.migratedArticles +
					' articles migrated. Refreshing.');
				window.location.reload();
			}
			else {
				alert('There was an error, please contact your administrator.');
			}
		},
	},
});
