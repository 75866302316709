import { defineStore } from 'pinia';
import axios from 'axios';

import type { Announcement } from '@/interfaces/AnnouncementInterface';
import type { ApiResponseAnnouncements } from '@/interfaces/ApiResponseInterface';
import type { ApiResponse } from '@/interfaces/ApiResponseInterface';
import type { Space } from '@/interfaces/SpaceInterface';
import type { ApiResponseSpaces } from '@/interfaces/ApiResponseInterface';

export const useAdminAnnouncementsStore = defineStore({
	id: 'adminAnnouncement',
	state: () => {
		return ({
			announcements: [] as Array<Announcement>,
			innactiveAnnouncementsLoaded: false,
			spaces: [] as Array<Space>,
			loaded: false,
		});
	},
	actions: {
		async loadSpaces() {
			const { data } = await axios.post<ApiResponseSpaces>('/space/list');
			this.spaces = data.spaces;		
		},
		async loadAnnouncements(includeInactive = false) {
			const { data } = await axios.post<ApiResponseAnnouncements>('/announcement/list', {
				dontShowPastAnnouncements: !includeInactive,
			});

			this.announcements = [...data.announcements];
			this.innactiveAnnouncementsLoaded = includeInactive;
			this.loaded = true;
		},
		async remove(announcement: Announcement) {
			await axios.post<ApiResponse>('/announcement/delete', {
				announcementId: announcement.AnnouncementID,
			});

			this.announcements = this.announcements.filter(a => a.AnnouncementID !== announcement.AnnouncementID);
		},
		async add(announcement: Announcement) {
			
			const { data } = await axios.post<ApiResponseAnnouncements>('/announcement/add', {
				copy: announcement.Copy,
				spaceId: announcement.Space.SpaceID,
				endDate: announcement.DateEnding,
			});
			
			this.announcements = [...data.announcements];
		},
	},
});
