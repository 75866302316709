import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-96c9f77c"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_logoSvg = _resolveComponent("logoSvg")!

  return (_openBlock(), _createElementBlock("a", {
    class: "logo",
    href: `https://${_ctx.tlcDomain}`,
    title: "Thought Leaders Central Home"
  }, [
    _createVNode(_component_logoSvg)
  ], 8 /* PROPS */, _hoisted_1))
}