import { createRouter, createWebHistory, RouterView, type Router } from 'vue-router';
import { currentUser } from '@/data/currentUserData';
import TlbsAlumniRenewalComponent from '@/components/TlbsAlumniRenewalComponent.vue';
import TlbsJoinComponent from '@/components/TlbsJoinComponent.vue';
import TypographyComponent from '@/components/main/admin/TypographyComponent.vue';
import CommitmentLinksComponent from '@/components/main/admin/CommitmentLinksComponent.vue';
import RouteNotFoundComponent from '@/components/shared/RouteNotFoundComponent.vue';
import RouteNotAuthorisedComponent from '@/components/main/RouteNotAuthorisedComponent.vue';
import AdminComponent from '@/components/main/admin/AdminComponent.vue';

declare module 'vue-router' {
	interface RouteMeta {
		requiresAdmin?: boolean
		requiresAuth?: boolean
		// The HTML head title tag
		title?: string
	}
}

let router: Router;
export const useMainRouter = () => {
	if(!router) {
		router = createRouter({
			history: createWebHistory('public/v'),
			routes: [
				{ path: '/', redirect: '/school-renewal' },
				{ 
					name: 'schoolRenewal', 
					path: '/alumni-school-renewal', 
					component: TlbsAlumniRenewalComponent,
					meta: { title: 'Alumni School Renewal' },
				},
				{ 
					name: 'schoolJoin', 
					path: '/join-the-school', 
					component: TlbsJoinComponent,
					meta: { title: 'Join The School' },
				},
				{
					path: '/admin',
					name: 'Admin',
					meta: { requiresAdmin: true, title: 'Admin' },
					component: RouterView,
					children: [
						{ path: '', name: 'Admin.Home', component: AdminComponent },
						{ path: 'typography', name: 'Admin.Typography', component: TypographyComponent, meta: { title: 'Typography' } },
						{ path: 'commitment-links', name: 'Admin.CommitmentLinks', component: CommitmentLinksComponent, meta: { title: 'Commitment Links' } },
					],
				},
				{ path: '/not-authorised', component: RouteNotAuthorisedComponent },
				{ path: '/:pathMatch(.*)', component: RouteNotFoundComponent },
			],
		});

		// Auth guard and login guards
		router.beforeEach((to) => {
			if (to.meta.requiresAuth && !currentUser.UserID) {
				return window.location.href = '/login';
			}

			if (to.meta.requiresAdmin && !currentUser.IsAdmin) {
				return {
					path: '/not-authorised',
				}
			}
		});

		// Update title tag in HTML
		const sitename = 'Thought Leaders Central';
		router.afterEach((to) => {
			document.title = to.meta.title ? `${to.meta.title} | ${sitename}` : sitename;
		});
	}
	
	return router;
}
