
import { defineComponent, PropType } from 'vue';
import { mapActions, mapState } from 'pinia';

import { usePaymentStore } from '@/stores/paymentStore';
import ButtonComponent from '@/components/ButtonComponent.vue';
import CardChooserComponent from '@/components/CardChooserComponent.vue';
import PriceComponent from '@/components/PriceComponent.vue';
import LoadingComponent from '@/components/presentation/LoadingComponent.vue';
import { ChargebeePlan } from '@/interfaces/ChargebeePlanInterface';
import { formatCents } from '@/utils/GeneralUtils';
import { usePlanStore } from '@/stores/planStore';
import { ChargebeePlanIdEnum } from '@/enums/ChargebeePlanIdEnum';
import { ChargebeeCard } from '@/interfaces/ChargebeeCardInterface';
import { currentUser } from '@/data/currentUserData';

export default defineComponent({
	components: {
		ButtonComponent,
		CardChooserComponent,
		LoadingComponent,
		PriceComponent,
	},
	props: {
		planIds: {
			type: Array as PropType<ChargebeePlanIdEnum[]>,
			required: true,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		country: {
			type: String,
			default: '',
		},
	},
	emits: ['plan-purchasing', 'plan-purchased', 'plan-purchased-failed'],
	data() {
		return {
			loading: true,
			selectedPlanId: '',
			selectedCard: {} as ChargebeeCard,
			currentUser,
		}
	},
	computed: {
		priceFormatted(): string {
			if(!this.selectedPlan) {
				return '';	
			}
			return formatCents(this.selectedPlan.Price);
		},
		selectedPlan(): ChargebeePlan | undefined {
			if(!this.plans.length) {
				return;
			}

			return this.plans.find(plan => plan.PlanId === this.selectedPlanId) ?? this.plans[0];
		},
		...mapState(usePaymentStore, ['loaded', 'primaryCard', 'hasValidCard']),
		...mapState(usePlanStore, ['plans']),
	},
	watch: {
		loaded: {
			handler(newVal: boolean) {
				if(newVal) {
					this.selectedCard = this.primaryCard;
				}
			},
		},
	},
	async beforeMount() {
		await this.getPlansWithAttendance(this.planIds);
		this.loading = false;
	},
	methods: {
		selectPlan(plan: ChargebeePlan) {
			this.selectedPlanId = plan.PlanId;
		},
		handlePurchasePlan() {
			if(!this.hasValidCard) {
				return this.openModalToAddCard();
			}

			if(!window.confirm(`Click "Ok" to process this purchase for a total of AUD ${((this.plans.length > 1) ? this.selectedPlan?.ButtonCopy : this.priceFormatted)}${(this.selectedPlan?.Taxable ? ' (+GST if applicable)' : '')} on your card ending with ${this.selectedCard.Last4}.`)) {
				return;
			}
			this.purchaseTicket();
		},
		async purchaseTicket() {
			if(!this.selectedPlan) {
				return;
			}

			this.$emit('plan-purchasing', [this.selectedPlan.PlanId]);
			var success = await this.purchasePlan(this.selectedPlan.PlanId, this.selectedCard, this.country);
			if (!success) {
				this.$emit('plan-purchased-failed', [this.selectedPlan.PlanId]);
				return;
			}
			this.$emit('plan-purchased', [this.selectedPlan.PlanId]);
		},
		cardChanged(selectedCard: ChargebeeCard) {
			this.selectedCard = selectedCard;
		},
		...mapActions(usePaymentStore, ['openModalToAddCard', 'toggleChoosingDifferentCard', 'loadCards']),
		...mapActions(usePlanStore, ['getPlansWithAttendance', 'purchasePlan']),
	},
});
