
import { defineComponent } from 'vue';

export default defineComponent({
	props: {
		subTitle: {
			type: String,
			default: '',
		},
		title: {
			type: String,
			default: '',
		},
	},
});
