
import { defineComponent } from 'vue';
import { google, outlook, office365, yahoo, ics, CalendarEvent } from 'calendar-link';

import appleIcon from '@/images/icons/calendars/apple-black.svg?inline';
import googleIcon from '@/images/icons/calendars/google.svg?inline';
import officeIcon from '@/images/icons/calendars/office.svg?inline';
import outlookIcon from '@/images/icons/calendars/outlook.svg?inline';
import yahooIcon from '@/images/icons/calendars/yahoo.svg?inline';

export default defineComponent({
	props: {
		title: {
			type: String,
			required: true,
		},
		// UTC
		startDate: {
			type: String,
			required: true,
		},
		// UTC
		endDate: {
			type: String,
			default: '',
		},
		description: {
			type: String,
			default: '',
		},
		location: {
			type: String,
			default: '',
		},
		
	},
	computed: {
		links() {
			const event = {
				title: this.title,
				// add the location to the button of the description as well
				// because some calendar types don't allow HTML in the description
				// so weo lose things like Zoom URLs if they are linked.
				description: this.description + '\r\n\r\nLocation: ' + this.location,
				start: this.startDate,
				location: this.location,
			} as CalendarEvent;

			if(this.endDate) {
				event.end = this.endDate;
			} else {
				event.duration = [1, 'hour'];
			}

			return [
				{
					link: google(event),
					name: 'Google',	
					icon: googleIcon,
				},
				{
					link: outlook(event),
					name: 'Outlook',	
					icon: outlookIcon,
				},
				{
					link: office365(event),
					name: 'Office365',	
					icon: officeIcon,
				},
				{
					link: yahoo(event),
					name: 'Yahoo',	
					icon: yahooIcon,
				},
				{
					link: ics(event),
					name: 'Apple & ICS',
					icon: appleIcon,
				},
			];
		},
	},
});
