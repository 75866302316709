
import { defineComponent } from 'vue';
import TlbsCommitmentAgreementComponent from '@/components/TlbsCommitmentAgreementComponent.vue';
import { ChargebeePlanIdEnum } from '@/enums/ChargebeePlanIdEnum';
import HeroImageComponent from '@/components/HeroImageComponent.vue';

export default defineComponent({
	components: { TlbsCommitmentAgreementComponent, HeroImageComponent }, 
	data() {
		return {
			ChargebeePlanIdEnum,
		}
	},
});
