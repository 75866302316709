
import { defineComponent } from 'vue';
import { mapState } from 'pinia';
import { useWikiStore } from '@/stores/wikiStore';
import WikiTreeItem from '@/components/curriculum/WikiTreeItemComponent.vue';
import WikiSearch from '@/components/curriculum/WikiSearchComponent.vue';

export default defineComponent({
	components: { WikiTreeItem, WikiSearch },
	computed: {
		...mapState(useWikiStore, ['wikiTree']),
	},
	beforeMount() {
		const wikiStore = useWikiStore();
		wikiStore.init();
	},
});
