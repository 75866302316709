
import { format } from 'date-fns';
import { PropType, defineComponent } from 'vue';
import { axiosClient } from '@/utils/AxiosUtil';
import { ApiResponse, ApiResponseHasPurchasedPlan } from '@/interfaces/ApiResponseInterface';
import { upcomingImmersionDates } from '@/utils/GeneralUtils';
import LoadingComponent from './presentation/LoadingComponent.vue';
import TlbsInformationComponent from '@/components/TlbsInformationComponent.vue';
import SidebarComponent from '@/components/presentation/SidebarComponent.vue';
import PlanPurchaseComponent from '@/components/PlanPurchaseComponent.vue';
import AuthLoggedInComponent from '@/components/main/auth/AuthLoggedInComponent.vue';
import SignatureComponent from './SignatureComponent.vue';
import { TlbsRenewalTypeEnum } from '@/enums/TlbsRenewalTypeEnum';
import { ChargebeePlanIdEnum } from '@/enums/ChargebeePlanIdEnum';
import { currentUser } from '@/data/currentUserData';
import { TCountries, countries }  from 'countries-list';

export default defineComponent({
	components: { LoadingComponent, PlanPurchaseComponent, SidebarComponent, TlbsInformationComponent, SignatureComponent, AuthLoggedInComponent }, 
	props: {
		planIds: {
			type: Array as PropType<ChargebeePlanIdEnum[]>,
			required: true,
		},
		isRenewal: {
			type: Boolean,
			required: true,
		},
		renewalType: {
			type: Number as PropType<TlbsRenewalTypeEnum>,
			default: null,
		},
	},
	data() {
		return {
			loading: false,
			joined: false,
			hasAlreadyPaid: false,
			signatureData: '',
			acceptedTerms: false,
			immersionChoice: '',
			currentUser,
			showCountries: true,
			countryChoice: '',
			countries: [] as Array<any>,
		}
	},
	computed: {
		canJoin() {
			let canJoin = this.acceptedTerms && !!this.signatureData;

			if(!currentUser.UserID) {
				canJoin &&= (!!this.currentUser.FirstName && !!this.currentUser.LastName &&!!this.currentUser.Email);
			}
			if (this.showCountries) {
				canJoin &&= this.countryChoice !== '';
			}

			return canJoin; 
		},
		upcomingSelectableImmersionDates() {
			const now = new Date();
			// Only show a third date if it's within a little over 6 months time
			return upcomingImmersionDates(3).filter(d => (d.getTime() - now.getTime()) < (1000* 60 * 60 * 24 * 190)).map(d => {
				return {
					key: format(d, 'yyyy-MM-dd'),
					value: format(d, 'MMM do yyyy'),
				}
			});
		},
	},
	async beforeMount() {
		if(currentUser.Country) {
			this.showCountries = false;
		}
		if (this.showCountries) {
			this.countries = Object.keys(countries).map((key) => { return { key: key, value: countries[key as keyof TCountries].name }}).sort(function(a, b) {
				return a.value.localeCompare(b.value);
			});
		}
		// only set a first immersion date if it's not a renewal
		if(!this.isRenewal) {
			this.immersionChoice = this.upcomingSelectableImmersionDates[0].key;
		}

		if(!currentUser.UserID) {
			return;
		}

		const { data } = await axiosClient.post<ApiResponseHasPurchasedPlan>('/chargebee/has-purchased-plans', {
			planIds: this.planIds,
			// 2 months in seconds
			secondsSince: (60 * 60 * 24 * 365 / 12) * 2, 
		});
		this.hasAlreadyPaid = data.hasPaid;
	},
	methods: {
		signatureChanged(signatureData: string) {
			this.signatureData = signatureData;
		},
		async handlePlanPurchased() {
			const { data } = await axiosClient.post<ApiResponse>('/login/public/programs/tlbs-commit', {
				signature: this.signatureData,
				isRenewal: this.isRenewal,
				renewalType: this.renewalType,
				immersionChoice: this.immersionChoice,
				user: currentUser.UserID ? null : currentUser,
			});
			this.loading = false;

			if(!data.success) {
				// this.clear();
				return alert(`There has been an error confirming your ${(this.isRenewal ? 're-' : '')}enrolment, please email finance@thoughtleaders.com.au.`);
			}

			this.joined = true;
		},
	},
});
