import axios from 'axios';
import { parseDateFromCfString } from '@/utils/GeneralUtils';

function handleDates(body: any) {
	if (body === null || body === undefined || typeof body !== 'object') {
		return body;
	}

	for (const key of Object.keys(body)) {
		const value = body[key];
		if (value && typeof value === 'string') {
			const parsedDate = parseDateFromCfString(value);
			if (parsedDate) {
				body[key] = parsedDate;
				continue;
			}
		} else if (typeof value === 'object') {
			handleDates(value);
		}
	}
}

export const axiosClient = axios.create();

axiosClient.interceptors.response.use(originalResponse => {
	handleDates(originalResponse.data);
	return originalResponse;
});