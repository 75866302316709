import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1520dc9b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "avatar" }
const _hoisted_2 = { class: "thought-belt-status" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "image",
      style: _normalizeStyle({
				backgroundImage: `url('${_ctx.user.ProfileImageURL}')`,
			})
    }, null, 4 /* STYLE */),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass(["thought-belt-annual thought-belt-colour", _ctx.user.Belt?.AnnualBelt?.DisplayColor?.toLowerCase()])
      }, null, 2 /* CLASS */),
      _createElementVNode("div", {
        class: _normalizeClass(["thought-belt-running-rate thought-belt-colour", _ctx.user.Belt?.RunningRateBelt?.DisplayColor?.toLowerCase()])
      }, null, 2 /* CLASS */)
    ])
  ]))
}