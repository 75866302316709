
import { defineComponent } from 'vue';
import TlbsCommitmentAgreementComponent from '@/components/TlbsCommitmentAgreementComponent.vue';
import HeroImageComponent from '@/components/HeroImageComponent.vue';
import { TlbsRenewalTypeEnum } from '@/enums/TlbsRenewalTypeEnum';
import { ChargebeePlanIdEnum } from '@/enums/ChargebeePlanIdEnum';

export default defineComponent({
	components: { TlbsCommitmentAgreementComponent, HeroImageComponent },
	data() {
		return {
			TlbsRenewalTypeEnum,
			ChargebeePlanIdEnum,
		}
	}, 
});
