
import { defineComponent } from 'vue';
import { CardComponent } from '@chargebee/chargebee-js-vue-wrapper';
import { mapActions, mapState, mapWritableState } from 'pinia';

import { usePaymentStore } from '@/stores/paymentStore';
import ModalComponent from '@/components/ModalComponent.vue';
import type { ModalButtonInterface } from '@/interfaces/ModalButtonInterface';
import { ChargebeeCardData } from '@/interfaces/ChargebeeCardDataInterface';
import { axiosClient } from '@/utils/AxiosUtil';
import type { ApiResponseChargebeeCardAdd } from '@/interfaces/ApiResponseInterface';
import type { ChargebeeCard } from '@/interfaces/ChargebeeCardInterface';

export default defineComponent({
	components: {
		CardComponent, 
		ModalComponent,
	},
	props: {
		alwaysShow: {
			type: Boolean,
			default: false,
		},
		subscriptionId: {
			type: String,
			default: null,
		},
	},
	emits: {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		selectedCardChanged(selectedCard: ChargebeeCard, subscriptionId: string) {
			return !!selectedCard;
		},
	},
	data() {
		return {
			cbCard: {
				fonts: ['https://fonts.googleapis.com/css?family=Poppins'],
				placeholder: {
					number: 'Card number',
					cvv: 'CVV',
					expiry: 'MM/YY',
				},
				locale: 'en',
				styles: {
					base: {
						fontFamily: 'Poppins, sans-serif',
						fontSize: '20px',
					},
				},
			},
			newCardIsValid: false,
			selectedCard: {} as ChargebeeCard,
		}
	},
	computed: {
		modalButtons(): ModalButtonInterface[] {
			return [
				{
					title: 'Add Card',
					disabled: !this.newCardIsValid,
					fn: this.handleAddNewCard,
				},
			];
		},
		...mapWritableState(usePaymentStore, ['loading']),
		...mapState(usePaymentStore, ['cards', 'primaryCard', 'hasValidCard', 'cardChooser']),
	},
	beforeMount() {
		this.loadCardChoices();
	},
	methods: {
		async loadCardChoices() {
			await this.loadCards();

			if (this.subscriptionId) {
				await this.loadSubscriptionCard();
			}
			else {
				this.selectedCard = this.primaryCard;
			}
		},
		handleAddNewCard() {
			if(this.newCardIsValid) {
				this.addNewCard();
			}
		},
		cbCardChanged(event: any) {
			const canSubmit = (event.complete && !event.error);
			this.newCardIsValid = canSubmit;
		},
		async addNewCard() {
			// Set the store loading to true now because this tokenise func does
			// a call through to Chargebee using the CB JS library
			this.loading = true;
			const cardData = await (this.$refs.card as any).tokenize() as ChargebeeCardData;
			var card = await this.addCard(cardData.vaultToken);

			this.closeModalToAddCard();
			this.newCardIsValid = false;
			this.setActiveCard(card);
		},
		async loadSubscriptionCard() {
			const { data } = await axiosClient.post<ApiResponseChargebeeCardAdd>('/chargebee/get-card-from-subscription', {
				subscriptionId: this.subscriptionId,
			});

			if (!data.success) {
				return window.alert('Sorry, there was an error retrieving the subscription card. Please contact us (finance@thoughtleaders.com.au, or via TLC) for assistance.')
			}
			this.selectedCard = this.cards.find(c => {
				return c.Id === data.card?.Id
			}) ?? {} as ChargebeeCard;
			
			if (!this.selectedCard.Id) {
				this.selectedCard = this.primaryCard;
			}
		},
		setActiveCard(card: ChargebeeCard) {
			if (card === this.selectedCard) {
				this.cardChooser.choosingDifferentCard = false;
				return;
			}
			this.updateSubscriptionCard(card);
			this.selectedCard = card;
			this.$emit('selectedCardChanged', this.selectedCard, this.subscriptionId);
			this.cardChooser.choosingDifferentCard = false;
		},
		async deleteCardChoice(card: ChargebeeCard) {
			var isSelectedCard = card === this.selectedCard;
			await this.deleteCard(card);
			if (isSelectedCard) {
				this.setActiveCard(this.primaryCard);
			}
		},
		async updateSubscriptionCard(card: ChargebeeCard) {
			if (!this.subscriptionId) {
				return;
			}
			const { data } = await axiosClient.post<ApiResponseChargebeeCardAdd>('/chargebee/set-card-on-subscription', {
				subscriptionId: this.subscriptionId,
				cardId: card.Id,
			});

			if (!data.success) {
				return window.alert('Sorry, there was an error updating the subscription card. Please contact us (finance@thoughtleaders.com.au, or via TLC) for assistance.')
			}
		},
		...mapActions(usePaymentStore, ['loadCards', 'addCard', 'closeModalToAddCard', 'openModalToAddCard', 'deleteCard']),
	},
});
