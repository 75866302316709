
import { defineComponent, type PropType } from 'vue';
import { mapState, mapActions } from 'pinia';

import { useXToYStore } from '@/stores/xToYStore';
import type { XToY } from '@/interfaces/XToYInterface';
import BeltType from '@/enums/BeltTypeEnum';
import ToolTipComponent from './ToolTipComponent.vue';

export default defineComponent({
	components: { ToolTipComponent },
	props: {
		initialXToY: {
			type: Object as PropType<XToY>,
			default: {} as XToY,
		},
	},
	data() {
		return {
			BeltType,
		}
	},
	computed: {
		...mapState(useXToYStore, ['belts', 'activeBelt', 'userAvatarImageURL', 'nextBeltDisplay']),
	},
	beforeMount() {
		const store = useXToYStore();
		store.setXToY(this.initialXToY);

		window.addEventListener('updateXToY', (res: any) => {
			store.setXToY(res?.detail?.xToY);
		});
	},
	methods: {
		beltClass(type: BeltType): string {
			if (this.activeBelt.Type === type) {
				return this.activeBelt.NextBeltColour.toLowerCase();
			}
			var currentBeltColour = this.currentBeltColour(type)
			// if the active belt is annual and it's current colour is the same as running rate, display both of their colours the same
			if (type === BeltType.Quarter && this.activeBelt.Type === BeltType.Year && currentBeltColour === this.currentBeltColour(BeltType.Year)) {
				return this.activeBelt.NextBeltColour.toLowerCase();
			}

			return currentBeltColour;
		},
		currentBeltColour(type: BeltType): string {
			return this.belts?.find(b => b.Type === type)?.CurrentBeltColour?.toLowerCase() || '';
		},
		...mapActions(useXToYStore, ['setBeltActive']),
	},
})
