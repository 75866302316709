
import { defineComponent } from 'vue';
import type { PropType } from 'vue';
import type { Streak } from '@/interfaces/StreakInterface';
import { currentUser } from '@/data/currentUserData';
import PersonAvatar from '@/components/PersonAvatarComponent.vue';

export default defineComponent({
	components: {
		PersonAvatar,
	},
	props: {
		streak: {
			type: Object as PropType<Streak>,
			default: {} as Streak,
		},
	},
	data() {
		return {
			currentUser,
		}
	},
	computed: {
		rankDisplay() {
			const rank = this.streak.Rank;
			var rem10 = rank % 10;
			var rem100 = rank % 100;
			if (rem10 === 1 && rem100 !== 11) {
				return rank + 'st';
			}
			if (rem10 === 2 && rem100 !== 12) {
				return rank + 'nd';
			}
			if (rem10 === 3 && rem100 !== 13) {
				return rank + 'rd';
			}
			return rank + 'th';
		},
	},
})
