<template>
	<div class="container">
		<ul>
			<li>
				<a href="/myaccount">
					Back to my account
				</a>
			</li>
			<li>
				<router-link to="/admin/typography">
					Typography
				</router-link>
			</li>
			<li>
				<router-link to="/admin/commitment-links">
					Commitment Links
				</router-link>
			</li>
		</ul>
	</div>
</template>