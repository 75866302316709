import ActivityStyle from '@/enums/ActivityStyleEnum';
import type { ActivityType } from '@/interfaces/ActivityTypeInterface';

export const ActivityTypeSale: ActivityType = {
	ActivityStyle: ActivityStyle.Monetary,
	ID: 1,
	DisplayName: 'Sale',
	GraphColor: '#0080c6',
};
export const ActivityTypeDelivery: ActivityType = {
	ActivityStyle: ActivityStyle.Monetary,
	ID: 2,
	DisplayName: 'Delivery',
	GraphColor: '#24b4e8',
};
export const ActivityTypeApproach: ActivityType = {
	ActivityStyle: ActivityStyle.Physical,
	ID: 1,
	DisplayName: 'Approach',
	GraphColor: '#2f8a35',
};
export const ActivityTypeMeeting: ActivityType = {
	ActivityStyle: ActivityStyle.Physical,
	ID: 2,
	DisplayName: 'Meeting',
	GraphColor: '#41ad49',
};
export const ActivityTypeProposal: ActivityType = {
	ActivityStyle: ActivityStyle.Physical,
	ID: 3,
	DisplayName: 'Proposal',
	GraphColor: '#36cc41',
};

export const activityTypes: ActivityType[] = [
	ActivityTypeSale,
	ActivityTypeDelivery,
	ActivityTypeApproach,
	ActivityTypeMeeting,
	ActivityTypeProposal,
];