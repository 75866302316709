
import { defineComponent } from 'vue';
import { CardComponent } from '@chargebee/chargebee-js-vue-wrapper';
import TlbsInformationComponent from '@/components/TlbsInformationComponent.vue';
import HeroImageComponent from '@/components/HeroImageComponent.vue';
import SidebarComponent from '@/components/presentation/SidebarComponent.vue';
import SignatureComponent from '@/components/SignatureComponent.vue';
import PriceComponent from '@/components/PriceComponent.vue';
import LoadingComponent from '@/components/presentation/LoadingComponent.vue';
import { axiosClient } from '@/utils/AxiosUtil';
import { ApiResponse, ApiResponsePlanPrice } from '@/interfaces/ApiResponseInterface';
import { formatCents } from '@/utils/GeneralUtils';
import { ChargebeeCardData } from '@/interfaces/ChargebeeCardDataInterface';
const now = new Date();
const nextMonth = new Date();
nextMonth.setMonth(now.getMonth() + 1);
var cardFontSize = window.innerWidth < 500 ? '16px' : '20px';

export default defineComponent({
	components: { TlbsInformationComponent, HeroImageComponent, CardComponent, SidebarComponent, SignatureComponent, PriceComponent, LoadingComponent },
	data() {
		return {
			loading: true,
			cbCard: {
				fonts: ['https://fonts.googleapis.com/css?family=Poppins'],
				placeholder: {
					number: 'Card number',
					cvv: 'CVV',
					expiry: 'MM/YY',
				},
				locale: 'en',
				styles: {
					base: {
						fontFamily: 'Poppins, sans-serif',
						fontSize: cardFontSize,
					},
				},
			},
			newCardIsValid: false,
			joined: false,
			acceptedTerms: false,
			signatureData: '',
			chargebeeLoaded: false,
			name: '',
			email: '',
			prorated: 0,
			total: 0,
			nowMonth: nextMonth.toLocaleString('en-us', { month: 'long' }),
			tlcDomain: process.env.TLC_DOMAIN,
		}
	},
	computed: {
		canSubmit() {
			return this.acceptedTerms && !!this.signatureData && this.name && this.email && this.newCardIsValid;
		},
		hasProRatedCharge() {
			return this.prorated > 0;
		},
	},
	beforeMount() {
		this.watchForChargebeeLoad();
		this.loadPrice();
	},
	methods: {
		formatCents,
		async loadPrice() {
			var { data } = await axiosClient.post<ApiResponsePlanPrice>('/login/public/programs/tlbs-alumni-commit-price');
			this.prorated = data.plan.Prorated;
			this.total = data.plan.Price;
			this.loading = false;
		},
		watchForChargebeeLoad() {
			if(window.Chargebee) {
				return this.chargebeeLoaded = true;
			}
		
			window.addEventListener('chargebeeLoaded', () => {
				this.chargebeeLoaded = true;
			});

			setTimeout(() => {
				if(!this.chargebeeLoaded) {
					window.alert('Sorry there was an error loading the payment form. Please refresh the page and try again.');
				}
			}, 1000 * 10);
		},
		cbCardChanged(event: any) {
			const canSubmit = (event.complete && !event.error);
			this.newCardIsValid = canSubmit;
		},
		signatureChanged(signatureData: string) {
			this.signatureData = signatureData;
		},
		async handleSubmit() {
			this.loading = true;
			const cardData = await (this.$refs.card as any).tokenize() as ChargebeeCardData;
			var { data } = await axiosClient.post<ApiResponse>('/login/public/programs/tlbs-alumni-commit', {
				name: this.name,
				email: this.email,
				signature: this.signatureData,
				cardToken: cardData.vaultToken,
				charge: this.prorated,
			});
			if(!data.success) {
				return window.alert(data.message || 'Sorry there was an error processing your enrolment. Please email finance@thoughtleaders.com.au.');
			}
			this.joined = true;
			this.loading = false;
		},
	},
});
