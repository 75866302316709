import { defineStore } from 'pinia';
import { axiosClient } from '@/utils/AxiosUtil';
import type { ApiResponseChargebeeGetInvoices, ApiResponseChargebeeGetSubscriptions } from '@/interfaces/ApiResponseInterface';
import type { ChargebeeInvoice } from '@/interfaces/ChargebeeInvoiceInterface';
import type { ChargebeeSubscription } from '@/interfaces/ChargebeeSubscriptionInterface';

export const useBillingStore = defineStore({
	id: 'billing',
	state: () => {
		return ({
			loading: false,
			invoices: [] as ChargebeeInvoice[],
			subscriptions: [] as ChargebeeSubscription[],
		});
	},
	getters: {
		activeAndFutureSubscriptions(state): ChargebeeSubscription[] {
			return state.subscriptions
				.filter(s => s.Status === 'Active' || s.Status === 'Future')
				.sort((a, b) => {
					if(a.Status !== b.Status) {
						return b.Status === 'Future' ? 1 : -1;
					}

					return 1;
				});
		},
	},
	actions: {
		async loadBilling(userId: number) {
			if(this.loading) {
				return;
			}
			this.loading = true;
			const invoicesAwait = axiosClient.post<ApiResponseChargebeeGetInvoices>('/chargebee/get-invoices', { userId });
			const subscriptionsAwait = axiosClient.post<ApiResponseChargebeeGetSubscriptions>('/chargebee/get-subscriptions', { userId });

			const [
				{ data: { invoices, success: invoicesSuccess } },
				{ data: { subscriptions, success: subscriptionsSuccess } },
			] = [
				await invoicesAwait,
				await subscriptionsAwait,
			];

			if(!invoicesSuccess || !subscriptionsSuccess) {
				return alert('There was an error loading your details, please email finance@thoughtleaders.com.au.')
			}
			
			this.invoices = invoices;
			this.subscriptions = subscriptions;

			this.loading = false;
		},
	},
});
