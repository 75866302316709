import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7209bad2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "vue-tl-price-component" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "price" }
const _hoisted_4 = {
  key: 0,
  class: "gst-star"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.cents > 0)
      ? (_openBlock(), _createElementBlock("h2", _hoisted_2, [
          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.formatCents(_ctx.cents)), 1 /* TEXT */),
          (_ctx.isTaxable)
            ? (_openBlock(), _createElementBlock("span", _hoisted_4, "*"))
            : _createCommentVNode("v-if", true)
        ]))
      : _createCommentVNode("v-if", true)
  ]))
}