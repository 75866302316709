import { createRouter, createWebHistory, type Router } from 'vue-router';
import WikiComponent from '@/components/curriculum/WikiComponent.vue';
import RouteNotFoundComponent from '@/components/shared/RouteNotFoundComponent.vue';

const path = window.location.pathname;
const customServerRoute = 'tl/curriculum/index';
const base = (path.indexOf(customServerRoute) >= 0 ? path.slice(0, path.indexOf(customServerRoute) + customServerRoute.length) : '');

let router: Router;
export const useCurriculumRouter = () => {
	if(!router) {
		router = createRouter({
			history: createWebHistory(base),
			routes: [
				{ path: '/', redirect: '/view' },
				{ name: 'wikiView', path: '/view/:id?/:slug?', component: WikiComponent },
				{ path: '/:pathMatch(.*)', component: RouteNotFoundComponent },
			],
			scrollBehavior(to) {
				if (to.hash) {
					return { el: to.hash }
				}
			},
		});
	}
	
	return router;
}