import { defineStore } from 'pinia';

import type { XToYBelt } from '@/interfaces/XToYBeltInterface';
import type { XToY } from '@/interfaces/XToYInterface';
import BeltType from '@/enums/BeltTypeEnum';

function daysTilEndOfMonth(): number {
	const dateToday = new Date();
	const lastDayOfMonth = new Date(dateToday.getFullYear(), dateToday.getMonth() + 1, 0).getDate();
	return lastDayOfMonth - dateToday.getDate();
}

function formatDollarAmount(amount: number): string {
	if(!amount) {
		return '';
	}

	const formatted = new Intl.NumberFormat('en-AU', { 
		style: 'currency', 
		currency: 'AUD',
		minimumFractionDigits: 0,
		maximumFractionDigits: 0,
	}).format(amount);

	return `<span>${formatted}</span>`;
}

export const useXToYStore = defineStore({
	id: 'xtoy',
	state: () => ({
		xtoy: {} as XToY,
	}),
	getters: {
		belts(): XToYBelt[] {
			return this.xtoy.Belts;
		},
		activeBelt(): XToYBelt {
			return this.xtoy?.Belts?.find(b => b.IsActive) || {} as XToYBelt;
		},
		userAvatarImageURL(): string {
			return this.xtoy.User.AvatarImageURL;
		},
		nextBeltDisplay(): string {
			if(this.activeBelt.IsAboveBlackBelt) {
				return `Congratulations your sales are above Black Belt for the ${BeltType[this.activeBelt.Type].toLowerCase()}!`;
			}

			const daysLeft = daysTilEndOfMonth();
			let nextBeltMessage = `Sell a further ${formatDollarAmount(this.activeBelt.SalesToNextBelt)}`;
			if (daysLeft > 0) {
				nextBeltMessage += ` in the next ${daysLeft} days <br>`;
			}
			else {
				nextBeltMessage += ` by the end of the day <br>`;
			}

			// monthly
			if (this.activeBelt.Type === 2) {
				return nextBeltMessage + `for a ${this.activeBelt.NextBeltColour} Belt month.`;
			}

			nextBeltMessage += ` ${(this.activeBelt.IsAboveCurrentBelt ? 'for a' : 'to get back to a')} ${this.activeBelt.NextBeltColour} Belt`;
			
			if(this.activeBelt.Type === 1) {
				nextBeltMessage += ' quarter';
			}

			return nextBeltMessage + '.';
		},
	},
	actions: {
		setXToY(xtoy: XToY) {
			this.xtoy = xtoy;
		},
		setBeltActive(belt: XToYBelt) {
			this.xtoy.Belts.forEach(b => b.IsActive = b.Type === belt.Type);
		},
	},
})
