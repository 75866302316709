
import { defineComponent } from 'vue';
import { getLocalObject, setLocalObject } from '@/utils/GeneralUtils';
import { currentSpace } from '@/data/generalData';

const localKey = `tlc.vue.SidebarCollapseComponent.isSidebarCollapsed.${currentSpace.SpaceID ?? 0}`;
let communityWrapper: HTMLDivElement | null;

export default defineComponent({
	data() {
		return {
			isSidebarCollapsed: false,
		};
	},
	beforeMount() {
		this.isSidebarCollapsed = getLocalObject(localKey) === true;

		communityWrapper = document.querySelector<HTMLDivElement>('.axero-community-wrapper');
		if(communityWrapper) {
			communityWrapper.classList.toggle('sidebar-collapsed', this.isSidebarCollapsed);
		}

		document.querySelectorAll<HTMLDataListElement>('.axero-community-wrapper .axero-space-container #axero-space-nav li').forEach(li => {
			li.addEventListener('click', () => {
				li.querySelector('a')?.click();
			});

			var title = li.querySelector('a')?.innerText || '';
			li.setAttribute('title', title);
		});
	},
	methods: {
		toggleSidebar() {
			this.isSidebarCollapsed = !this.isSidebarCollapsed;
			setLocalObject(localKey, this.isSidebarCollapsed);
			
			if(communityWrapper) {
				communityWrapper.classList.toggle('sidebar-collapsed', this.isSidebarCollapsed);
			}
		},
	},
});
