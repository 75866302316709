import { addDays, fromUnixTime } from 'date-fns';

export const debounce = (fn: Function, ms = 300) => {
	let timeoutId: ReturnType<typeof setTimeout>;
	return function (this: any, ...args: any[]) {
		clearTimeout(timeoutId);
		timeoutId = setTimeout(() => fn.apply(this, args), ms);
	};
};

export const parseDateFromCfString = (str: string): Date | null => {
	// Add an optional negative in there so that dates before 1970 work correctly
	const jsonDateRegex = /^\/Date\((-?\d+)\)\/$/;
	const result = str.match(jsonDateRegex);
	if (result?.length === 2) {
		const s = parseInt(result[1]) / 1000;
		return fromUnixTime(s);
	}

	return null;
};

export const formatCents = (cents: number) => {
	return '$' + (cents / 100).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
}

// https://www.geeksforgeeks.org/how-to-convert-a-string-into-kebab-case-using-javascript/
export const toKebabCase = (str: string) => {
	return str
		.replace(/([a-z])([A-Z])/g, '$1-$2')
		.replace(/[\s_]+/g, '-')
		.toLowerCase();
};


export const upcomingImmersionDates = (count: number = 4) => {
	const monthsWithImmersion: number[] = [1, 4, 7, 10];
	const upcomingImmersions = [];
	const now = new Date();
	let checkingDay = new Date();
	// Use this to ensure our loop stops incase we do something 
	// silly with dates, which is likely, since dates in JS are fucked.
	// If TL is still running this code after this date...wow
	const emerencyFinalDate = new Date(2100, 0, 1);

	while(upcomingImmersions.length < count && checkingDay < emerencyFinalDate) {
		const currentMonthNumer = checkingDay.getMonth();
		const dayOfWeek = checkingDay.getDay();
		const dayOfMonth = checkingDay.getDate();

		const isInFirstWeekOfMonth = (dayOfMonth <= 7);
		const isInImmersionMonth = monthsWithImmersion.some(m => m === currentMonthNumer);
		const isThursday = (dayOfWeek === 4);
		const isInLessThanOneDay = ((checkingDay.getTime() - now.getTime()) / (1000 * 3600 * 24)) <= 1;

		if(!isInLessThanOneDay && isInFirstWeekOfMonth && isInImmersionMonth && isThursday) {
			upcomingImmersions.push(checkingDay);
		}

		checkingDay = addDays(checkingDay, 1);
	}

	return upcomingImmersions;
};

// Yes this is gross, but typescript doesn't have any better
// support for enums. This should be use sparingly and only when
// working with other systems
export const getEnumFromString = <T>(str: string): T => {
	return str as unknown as T;
}

export const getLocalObject = (key: string): any => {
	const val = window.localStorage.getItem(key);
	if(!val) {
		return {};
	}

	return JSON.parse(val);
}

export const setLocalObject = (key: string, val: object | boolean): void => {
	window.localStorage.setItem(key, JSON.stringify(val));
}

export const isDebug = () => {
	return (
		window.location.host.indexOf('thoughtleaders.local') === 0 ||
		window.location.host.indexOf('localhost') === 0
	);
};

// Backwards compatibility for Communifire utils since
// it was removed from site-tl-utils.js
window.tlUtils = window.tlUtils || {};
window.tlUtils.isDebug = isDebug;