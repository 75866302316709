
import { defineComponent } from 'vue';

export default defineComponent({
	props: {
		loading: {
			type: Boolean,
			default: false,
		},
		fullScreen: {
			type: Boolean,
			default: false,
		},
		outerBorder: {
			type: Boolean,
			default: false,
		},
	},
});
