import { defineStore } from 'pinia';
import axios from 'axios';

import { curriculum } from '@/data/generalData';
import { currentUser } from '@/data/currentUserData';
import type { Wiki } from '@/interfaces/WikiInterface';
import type { ApiResponseWikis } from '@/interfaces/ApiResponseInterface';
import type { LearningResult } from '@/interfaces/LearningResultInterface';

export const useWikiStore = defineStore({
	id: 'wiki',
	state: () => ({
		wikis: [] as Array<Wiki>,
		isLoading: false,
		currentWikiId: 0,
		learningResults: {} as LearningResult,
	}),
	getters: {
		wikiTree(state): Wiki {
			if(!state.wikis) {
				return {} as Wiki;
			}
			const wikis = [...state.wikis];

			const idMapping = wikis.reduce((acc, el, i) => {
				acc[el.ContentID] = i;
				return acc;
			}, {} as any);

			let root = undefined as unknown as Wiki;
			wikis.forEach(el => {
				// Handle the root element
				if (el.ParentContentID === 0) {
					root = el;
					return;
				}
				const parentEl = wikis[idMapping[el.ParentContentID]];
				if (!parentEl){
					return;
				}
				parentEl.children = [...(parentEl.children || []), el];
			});

			return root;
		},
		currentWiki(state): Wiki {
			if(state.currentWikiId <= 0) {
				return state.wikis.find(w => w.ParentContentID === 0) || {} as Wiki;
			}

			return state.wikis.find(w => w.ContentID === state.currentWikiId) || {} as Wiki;
		},
		prevNextWiki(): { prev: Wiki | undefined, next: Wiki | undefined } {
			const currentWiki = this.currentWiki;
			const tree = this.wikiTree;

			if(!currentWiki || !tree) {
				return { prev: undefined, next: undefined };
			} 

			let prev = undefined as unknown as Wiki;
			let next = undefined as unknown as Wiki;
			let stopAtNext = false;

			function lookForWiki(w: Wiki) {
				if(stopAtNext) {
					if(!next) {
						next = w;
					}

					return;
				}

				if(w.ContentID === currentWiki.ContentID) {
					stopAtNext = true;
				} else {
					prev = w;
				}

				if(w.children) {
					w.children.forEach(c => {
						lookForWiki(c);
					});
				}
			}

			lookForWiki(tree);

			return {
				prev,
				next,
			}
		},
	}, 
	actions: {
		async init() {
			if(this.wikis.length > 0 && this.isLoading) {
				return;
			}
			this.isLoading = true;
			
			// don't await this because it doesn't matter if it loads late,
			// the main thing we are waiting on are the wikis
			this.syncLearning();
			await this.getWikis();
			
			this.isLoading = false;

		},
		setCurrentWiki(id: number) {
			this.currentWikiId = id;
		},
		async getWikis() {
			const { data } = await axios.post<ApiResponseWikis>('/curriculum/get-wikis');
			this.wikis = data.wikis;
		},
		async syncLearning() {
			// have to use form data so that WP can understand this and trigger
			// the correct action
			const formData = new FormData;
			formData.append('action', 'getUserCourseProgress');
			formData.append('userEmail', currentUser.Email);
			formData.append('apiUser', curriculum.api.user);
			formData.append('apiPass', curriculum.api.pass);

			const { data } = await axios.post<LearningResult>(curriculum.api.url, formData);
			this.learningResults = data;
		},
	},
});