<template>
	<div
		class="vue-tl-widget-container-component"
	>
		<slot />
	</div>
</template>

<style scoped lang="scss">
.vue-tl-widget-container-component {
	margin-bottom: 30px;
	border-radius: var(--tl-border-radius);
	overflow: hidden;
}
</style>