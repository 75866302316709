import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.loading)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["vue-tl-loading-component", {
			'full-screen': _ctx.fullScreen,
			'outer-border': _ctx.outerBorder
		}])
      }, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ], 2 /* CLASS */))
    : _createCommentVNode("v-if", true)
}