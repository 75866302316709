
// This component handles string searhing of the store wikis and shows
// a highlighted list of title and content. As the user types we do a
// debounced search using some basic regex on the title and body, 
// and by default return only 5 results with a weighting based on
// how many matches. 
//
// From these 5 results we then use Mark.js to mark the title using mostly
// default options. But since the bodys can be quite large, we first use
// a more complex regex to find the first occurance of one of the search terms,
// including some chars before and after it, and then run Mark.js on that 
// smaller content. 
//
// Its quite performant, less than 20ms all up. You can also choose
// to see all the matches, ie more than the 5, and then we run the marking
// on all of the results.

import { defineComponent } from 'vue';
import { useWikiStore } from '@/stores/wikiStore';
import { mapState } from 'pinia';
import * as Mark from 'mark.js';
import { debounce } from '@/utils/GeneralUtils';
import type { Wiki } from '@/interfaces/WikiInterface';

const titleWeight = 2;
const resultLimit = 5;
const bodyMatchSpread = 60;

function stringToElement(str: string): HTMLElement {
	var div = document.createElement('div');
	str = str.trim(); // Never return a text node of whitespace as the result
	div.innerHTML = str;
	return div;
}

export default defineComponent({
	data() {
		return {
			search: '',
			debouncedSearch: () => {},
			results: [] as {
				w: Wiki,
				matches: number,
				titleMatches: number,
				bodyMatches: number,
			}[],
			showAllResults: false,
		}
	},
	computed: {
		...mapState(useWikiStore, ['wikis']),
		visibleResults() {
			if(this.search.trim().length <= 0) {
				return [];
			}

			// By default lets only mark/look at only 5 to cut down the
			// processing unless we need to
			let toReturn = this.showAllResults ? this.results : this.results.slice(0, resultLimit);

			let final = toReturn.map(res => {
				// mark the title normally, as is
				const titleEl = stringToElement(res.w.ContentTitle);
				new Mark(titleEl).mark(this.search);

				// Get the text from the body, that is, get rid of all the 
				// HTML
				const bodyText = stringToElement(res.w.ContentBody).textContent || '';
				const terms = this.search.trim().split(' ');
				const regexStr = terms.join('|');
				// Match the search terms, which a bunch of chars either side, the reason 
				// being it gives context to the highlighted terms if we can see what
				// words/sentences surround the searched terms. We add a \s on each end
				// so that the regex favours whole words on either end to make it more
				// readable.
				const regex = new RegExp(`\\s(?<pre>.{0,${bodyMatchSpread}})(?:${regexStr})(?<post>.{0,${bodyMatchSpread}})\\s`, 'i');
				var bodyMatchesResult = bodyText.match(regex);
				let body = '';

				if(bodyMatchesResult) {
					const firstFullMatch = bodyMatchesResult[0];
					let stringToUse = firstFullMatch;
					// If either the pre or post search term selected strings
					// are as big as the max allowed, then it means we may have
					// cut a word in half, so we put elipses on this. It doesn't
					// matter too much if this is not perfect, because extra 
					// epilsies won't make it too much more confusing.
					if(bodyMatchesResult.groups!.pre.length >= bodyMatchSpread) {
						stringToUse = '...' + stringToUse;
					}
					if(bodyMatchesResult.groups!.post.length >= bodyMatchSpread) {
						stringToUse = stringToUse + '...';
					}

					const bodyEl = stringToElement(stringToUse);
					new Mark(bodyEl).mark(this.search);
					body = bodyEl.innerHTML;
				}
				
				return {
					matches: res.matches,
					id: res.w.ContentID,
					stub: res.w.ContentStub,
					title: titleEl.innerHTML,
					body,
				}
			});

			return final;
		},
	},
	created() {
		// the initial search which returns all 
		// matched wikis. The reason we have to search through all 
		// the wikis is so we can weight them appropriately.
		this.debouncedSearch = debounce(() => {
			const terms = this.search.trim().split(' ');
			const regexStr = terms.join('|');

			const regex = new RegExp(regexStr, 'ig');
			let keepLooping = true;
			let index = 0;
			const searched = [];
			while(keepLooping) {
				const w = this.wikis[index];
				index++;

				var matches = 0;
				var titleMatchesResult = w.ContentTitle.match(regex);
				var bodyMatchesResult = w.ContentBody.match(regex);

				// weigh title matches higher
				var titleMatches = titleMatchesResult ? (titleMatchesResult.length * titleWeight) : 0
				var bodyMatches = bodyMatchesResult ? bodyMatchesResult.length : 0;

				matches += titleMatches;
				matches += bodyMatches;

				if(matches > 0) {
					searched.push({
						matches,
						titleMatches,
						bodyMatches,
						w,
					});
				}

				if(index >= this.wikis.length) {
					keepLooping = false;
				}
			}

			// sort by the weighting of matches
			this.results = searched.sort((a, b) => {
				return b.matches - a.matches;
			});
		}, 300);
	},
	
});
