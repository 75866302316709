import * as Sentry from '@sentry/browser';
import { BrowserTracing } from '@sentry/tracing';
import { currentUser } from '@/data/currentUserData';

export const sentryScript = () => {
	Sentry.init({
		dsn: process.env.SENTRY_DSN,
		environment: (function () {
			if((window.location.host.indexOf('thoughtleaders.local') === 0) || (window.location.host.indexOf('localhost') === 0)) {
				return 'development';
			}
        
			return 'production';
		})(),
		// https://docs.sentry.io/clients/javascript/tips/
		ignoreErrors: [
		// Random plugins/extensions
			'top.GLOBALS',
			// See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error. html
			'originalCreateNotification',
			'canvas.contentDocument',
			'MyApp_RemoveAllHighlights',
			'http://tt.epicplay.com',
			"Can't find variable: ZiteReader",
			'jigsaw is not defined',
			'ComboSearch is not defined',
			'http://loading.retry.widdit.com/',
			'atomicFindClose',
			// Facebook borked
			'fb_xd_fragment',
			// ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
			// See http://stackoverflow.com/questions/4113268
			'bmi_SafeAddOnload',
			'EBCallBackMessageReceived',
			// See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
			'conduitPage',
		], 
		allowUrls: [
			/thoughtleaderscentral\.com/i,
			/learning\.thoughtleaderscentral\.com/i,
			/thoughtleaders\.local/i,
			/localhost/i,
		],
		integrations: [new BrowserTracing()],
		// Don't send common Communifire errors (see documentation/KnownCommunifireIssues for more info)
		beforeSend(event, hint) {
			const error = hint.originalException as any;
			if (error && error.message && error.stack &&
				(error.message === 'Cannot read properties of undefined (reading \'text\')' && error.stack.match(/myaccount\/articles\/addedit/i)
				|| error.message === 'Cannot read properties of undefined (reading \'options\')' &&  error.stack.match(/jquery\/bootstrap-select\/bootstrap-select.js/i))
				|| error.message === 'undefined is not an object (evaluating \'ceCurrentVideo.currentTime\')'
				|| error.message === 'Failed to execute \'setBaseAndExtent\' on \'Selection\': There is no child at offset 1.'
				|| error.message === 'Non-Error exception captured with keys: currentTarget, isTrusted, target, type'
				|| error.message === 'undefined is not an object (evaluating \'a.find(\'.tl-notice-board-body .tl-content[id="\'+n+\'"]\').offset().top\')'
				|| error.message === 'Cannot read properties of undefined (reading \'top\')'
				|| error.message === '$(...).tipsy is not a function'
				// the below two filters may catch real errors, but Sentry gets filled with these errors
				|| (error.name && error.name === 'AxiosError' && error.message === 'Network Error')
				|| error.message === 'Action timed out') 
			{
				return null;
			}
			if (error && error.stack && error.stack.match(/jquery.signalR-2.2.0.js/i)) {
				return null;
			}
			if (event.breadcrumbs && event.breadcrumbs.length > 0)
			{
				const lastBreadcrumb = event.breadcrumbs[event.breadcrumbs.length - 1].data;
				if (lastBreadcrumb && lastBreadcrumb.url && (lastBreadcrumb.url.match(/\/webServices\/CommonWebService.asmx/i)
										|| lastBreadcrumb.url.match(/\/webServices\/WallWebService.asmx/i)
										|| lastBreadcrumb.url.match(/\/api\/activityfeed\?userid=/i)
										|| lastBreadcrumb.url.match(/\/myaccount\/ajaxtemplates/i)
										|| lastBreadcrumb.url.match(/\/AjaxTemplates\/ActivityStreamTemplates/i)
										|| lastBreadcrumb.url.match(/https:\/\/www.google-analytics.com/i)
										|| lastBreadcrumb.url.match(/\/cdn-cgi\/mirage_speedtest/i)))
				{
					return null;
				}
			}
			return event;
		},
	});

	if (currentUser.UserID) {
		Sentry.setUser({
			'email': currentUser.Email,
			'username': currentUser.UserName,
			'id': currentUser.UserID.toString(),
		});
	}

	if(window.jQuery) {
		window.jQuery(document).ajaxError(function (event: any, jqXHR: any, ajaxSettings: any, thrownError: any) {
			if (ajaxSettings && ajaxSettings.url &&
				(!jqXHR.responseText // this may also catch real errors that don't have a response, but Sentry gets filled with these errors
				|| ajaxSettings.url.match(/\/api\/activityfeed\?userid=/i))
				|| ajaxSettings.url.match(/\/users\/avatar-modification-for-users/i)
				|| ajaxSettings.url.match(/\/ajaxtemplates\//i)
				|| ajaxSettings.url.match(/\/jsonresources\/getmentionusers/i)
				|| (jqXHR.response && jqXHR.responseText.includes('Login to your account | Thought Leaders')))
			{
				return;
			}
			Sentry.withScope(function(scope) {
				scope.setFingerprint([window.location.pathname]);
				scope.setExtra('data', {
					type: ajaxSettings.type,
					url: ajaxSettings.url,
					data: ajaxSettings.data,
					status: jqXHR.status,
					error: thrownError || jqXHR.statusText,
					response: (jqXHR.responseText ? jqXHR.responseText.substring(0, 100) : 'No response.'),
				});
	
				Sentry.captureException(new Error(thrownError || jqXHR.statusText));
			});
		});
	}
}