
// This component could potentially be merged with SpaceAddEditTagList

import { defineComponent } from 'vue';
import type { PropType } from 'vue';

import type { TagGroup } from '@/interfaces/TagGroupInterface';
import type { Tag } from '@/interfaces/TagInterface';

export default defineComponent({
	props: {
		initialTagGroups: {
			type: Array as PropType<Array<TagGroup>>,
			default: [] as Array<TagGroup>,
		},
	},
	data: () => {
		return {
			tagGroups: [] as Array<TagGroup>,
		}
	},
	created: function () {
		// Add class to allow us to hide the original only if this component loads
		document.body.classList.add('vue-space-tag-filter');

		const urlTagsString = (new URL(window.location.href)).searchParams.get('tags');
		const urlTags = urlTagsString === null ? [] : urlTagsString.split('+');

		this.tagGroups = this.initialTagGroups.map((tagGroup: TagGroup) => {
			const tagGroupNameLower = tagGroup.TagGroupName.toLowerCase();

			tagGroup.Tags = tagGroup.Tags.map((tag: Tag) => {
				tag.isActive = (urlTags.indexOf(tag.TagName) >= 0);
				tag.displayName = tag.TagName.replace(tagGroupNameLower + '-', '');

				const url = new URL(window.location.href);
				let newUrlTags = [];
				if (tag.isActive) {
					newUrlTags = urlTags.filter(t => t !== tag.TagName);
				} else {
					newUrlTags = [...urlTags, tag.TagName];
				}
				url.searchParams.set('tags', newUrlTags.join('+'));
				tag.href = url.toString();

				return tag;
			});

			tagGroup.isOpen = tagGroup.Tags.some((t: Tag) => t.isActive);

			return tagGroup;
		}).sort((a: TagGroup, b: TagGroup) => a.TagGroupID - b.TagGroupID);
	},
});
