import { currentSpace } from '@/data/generalData';
import { getLocalObject, setLocalObject } from '@/utils/GeneralUtils';

const defaultKey = 'tlc.js.activityFeedFilterDefault';
const filterTypeIdMap = {
	'wallactivity': 1,
	'wall': 2, // woym
	'activity': 3,
} as any;

export const activityScript = () => {
	const filterParent = document.querySelector('.axero-ultimate-wall-container .axero-my-status-filter ul');
	if(!filterParent) {
		return;
	}

	// Get the filter we should use
	const defaults = getLocalObject(defaultKey);
	const spaceId = currentSpace.SpaceID || 0;
	const spaceDefault = (spaceId in defaults) ? defaults[spaceId] : 2;
	const rel = Object.keys(filterTypeIdMap).find(key => filterTypeIdMap[key] === spaceDefault)

	filterParent.querySelectorAll('a').forEach(link => {
		// Set filter link active state
		const attr = link.getAttribute('rel');
		if(attr === rel) {
			link.classList.add('selected');
		} else {
			link.classList.remove('selected');
		}

		// Update the filter in localStorage on click
		link.addEventListener('click', () => {
			const rel = link.getAttribute('rel') as string;
			const id = filterTypeIdMap[rel];

			const defaults = getLocalObject(defaultKey);
			const spaceId = currentSpace.SpaceID?.toString() || '0';
			defaults[spaceId] = id;
			setLocalObject(defaultKey, defaults);
		});
	});
}