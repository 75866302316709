import { createApp } from 'vue';
import { createPinia } from 'pinia';
import ready from 'document-ready';
import VueGtag, { PageView } from 'vue-gtag';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import VueSignaturePad from 'vue-signature-pad';

import GlobalComponent from '@/components/GlobalComponent.vue';
import SpaceWidgetFpImmersionCountdown from '@/components/SpaceWidgetFpImmersionCountdownComponent.vue'
import SpaceTagFilter from '@/components/SpaceTagFilterComponent.vue'
import SpaceAddEditTagList from '@/components/SpaceAddEditTagListComponent.vue'
import AnnouncementManager from '@/components/AnnouncementManagerComponent.vue'
import AnnouncementList from '@/components/AnnouncementListComponent.vue'
import AnnouncementAdmin from '@/components/AnnouncementAdminComponent.vue'
import EventRsvp from '@/components/EventRsvpComponent.vue';
import XToYDashboard from '@/components/XToYDashboardComponent.vue';
import UsersByBelts from '@/components/UsersByBeltsComponent.vue';
import SpacePeople from '@/components/SpacePeopleComponent.vue';
import CalendarAddComponent from '@/components/CalendarAddComponent.vue';
import CurriculumComponent from '@/components/curriculum/CurriculumComponent.vue';
import DashboardGraphComponent from '@/components/DashboardGraphComponent.vue';
import UserBeltAchievementsComponent from '@/components/UserBeltAchievementsComponent.vue';
import EventBBPRetreatComponent from '@/components/EventBBPRetreatComponent.vue';
import EventFijiBsComponent from '@/components/EventFijiBsComponent.vue';
import BillingComponent from '@/components/BillingComponent.vue';
import TlbsJoinComponent from '@/components/TlbsJoinComponent.vue';
import MagicLinksComponent from '@/components/MagicLinksComponent.vue';
import TlbsOfferRenewalPifComponent from '@/components/TlbsOfferRenewalPifComponent.vue';
import TlbsOfferAlumniPifComponent from '@/components/TlbsOfferAlumniPifComponent.vue';
import TlbsOfferDiscountTilFebComponent from '@/components/TlbsOfferDiscountTilFebComponent.vue';
import FpToBsComponent from '@/components/FpToBsComponent.vue';
import BroadcastAdminComponent from '@/components/BroadcastAdminComponent.vue';
import SidebarCollapserComponent from '@/components/SidebarCollapserComponent.vue';
import MainComponent from '@/components/main/MainComponent.vue';
import BookingMarkButlerComponent from '@/components/BookingMarkButlerComponent.vue';
import StreakLeaderboardComponent from '@/components/StreakLeaderboardComponent.vue';
import CsvImporterComponent from '@/components/CsvImporterComponent.vue';
import ToolTipContainerComponent from '@/components/ToolTipContainerComponent.vue';
import StreakProgressBarComponent from '@/components/StreakProgressBarComponent.vue';
import ProgressBarComponent from '@/components/ProgressBarComponent.vue';

import { useCurriculumRouter } from '@/routers/curriculumRouter';
import { useMainRouter } from '@/routers/mainRouter';

import { hubspotScript } from '@/ts/hubspotScript';
import { chargeebeeScript } from '@/ts/chargebeeScript';
import { sentryScript } from '@/ts/sentryScript';
import { profileScript } from '@/ts/profileScript';
import { activityScript } from '@/ts/activityFeedScript';

sentryScript();
chargeebeeScript();
ready(() => {
	hubspotScript();
	profileScript();
	activityScript();
});

const vueComponentsToLoad = [
	{ selector: '#space-widget-fp-immersion-countdown', component: SpaceWidgetFpImmersionCountdown },
	{ selector: '#space-tag-filter', component: SpaceTagFilter },
	{ selector: '#space-add-edit-tag-list', component: SpaceAddEditTagList },
	{ selector: '#announcement-manager', component: AnnouncementManager },
	{ selector: '.announcement-list', component: AnnouncementList },
	{ selector: '#announcement-admin', component: AnnouncementAdmin },
	{ selector: '#vue-event-rsvp', component: EventRsvp },
	{ selector: '#vue-x-to-y-progress-bar', component: XToYDashboard },
	{ selector: '#vue-users-by-belts-report', component: UsersByBelts },
	{ selector: '#vue-curriculum', component: CurriculumComponent, router: useCurriculumRouter },
	{ selector: '#vue-people', component: SpacePeople },
	{ selector: '.vue-add-to-calendar', component: CalendarAddComponent },
	{ selector: '#vue-global', component: GlobalComponent },
	{ selector: '#vue-dashboard-graph', component: DashboardGraphComponent },
	{ selector: '#vue-user-belt-achievements', component: UserBeltAchievementsComponent },
	{ selector: '#vue-event-black-belt-program-retreat', component: EventBBPRetreatComponent },
	{ selector: '#vue-event-fiji-bs', component: EventFijiBsComponent },
	{ selector: '#vue-profile-billing', component: BillingComponent },
	{ selector: '#vue-magic-links', component: MagicLinksComponent },
	{ selector: '#vue-cms-commitment-agreement', component: TlbsJoinComponent },
	{ selector: '#vue-cms-tlbs-offer-renewal-pif', component: TlbsOfferRenewalPifComponent },
	{ selector: '#vue-cms-tlbs-offer-alumni-pif', component: TlbsOfferAlumniPifComponent },
	{ selector: '#vue-cms-commitment-agreement---honoured-pricing', component: TlbsOfferDiscountTilFebComponent },
	{ selector: '#vue-fp-to-bs', component: FpToBsComponent },
	{ selector: '#vue-broadcast-admin', component: BroadcastAdminComponent },
	{ selector: '#vue-sidebar-collapser', component: SidebarCollapserComponent },
	{ selector: '#vue-booking-mark-butler', component: BookingMarkButlerComponent },
	{ selector: '#vue-streak-leaderboard', component: StreakLeaderboardComponent },
	{ selector: '#vue-csv-importer', component: CsvImporterComponent },
	{ selector: '#vue-tooltip-container', component: ToolTipContainerComponent },
	{ selector: '#vue-streak-progress-bar', component: StreakProgressBarComponent },
	{ selector: '#vue-dashboard-progress-bar', component: ProgressBarComponent },
	{ selector: '#vue-web-app', component: MainComponent, router: useMainRouter },
];

const piniaInstance = createPinia();
piniaInstance.use(piniaPluginPersistedstate);

vueComponentsToLoad.forEach((comp) => {
	const els = document.querySelectorAll(comp.selector) as NodeListOf<HTMLElement>;
	if (els.length <= 0) {
		return;
	}

	els.forEach(el => {
		el.classList.add('vue-component');

		// https://filosophy.org/code/passing-props-to-a-root-vue-component-from-its-surrounding-html/
		const props = Object.keys(el.dataset).reduce((acc: any, propName: string) => {
			const propJson = el.dataset[propName];
			if(propJson) {
				acc[propName] = JSON.parse(propJson);
			}
			return acc;
		}, {});

		const app = createApp(comp.component, { ...props })
		app.use(piniaInstance);
		app.use(VueSignaturePad);
		
		if(comp.router) {
			// we're using a router singleton here because if we don't, the router is
			// created on every page load, even if the component that is using the 
			// router isn't mounted into the page. This way we can import the use
			// function, and then execute it below only when we need it (not when
			// the import happens).
			app.use(comp.router());
            
			if(process.env.GOOGLE_ANALYTICS_ID) {
				app.use(VueGtag, {
					pageTrackerTemplate() {
						// The typescript definition for this function expects no params, 
						// but we want to use them, so to satisfy the compiler lets not
						// use any explict params but then grab it from `arguments` and
						// then tell TS to ignore using `arguemnts` in favour of a rest param
						// eslint-disable-next-line prefer-rest-params
						const to = arguments[0];
						return {
							page_title: `Curriculum View - ${(to.params.slug ? to.params.slug : 'Root')}`,
							page_path: to.href,
						} as PageView
					},
					config: { id: process.env.GOOGLE_ANALYTICS_ID },
				}, comp.router());
			}
		}
		app.mount(el);
	});
});