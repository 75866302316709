
import { defineComponent, PropType } from 'vue';
import * as pluralize from 'pluralize';
import { mapState } from 'pinia';
import { usePlanStore } from '@/stores/planStore';
import PlanPurchaseComponent from '@/components/PlanPurchaseComponent.vue';
import LoadingComponent from '@/components/presentation/LoadingComponent.vue';
import { ChargebeePlanIdEnum } from '@/enums/ChargebeePlanIdEnum';

export default defineComponent({
	components: {
		PlanPurchaseComponent,
		LoadingComponent,
	},
	props: {
		planIds: {
			type: Array as PropType<ChargebeePlanIdEnum[]>,
			required: true,
		},
		canPurchaseMultipleTickets: {
			type: Boolean,
			required: true,
		},
	},
	data() {
		return {
			loading: false,
			wantToBuyAnotherTicket: false,
		}
	},
	computed: {
		subscriptionCount(): number {
			return this.plans.reduce((acc, p) => {
				acc += p.SubscriptionCount;
				return acc;
			}, 0);
		},
		countCopy(): string {
			return (this.subscriptionCount === 1 ? 'a' : this.subscriptionCount) + ' ' + pluralize('ticket', this.subscriptionCount) + '.';
		},
		hasAnyTickets(): boolean {
			return this.subscriptionCount > 0;
		},
		showConfirmation(): boolean {
			return !this.wantToBuyAnotherTicket && this.hasAnyTickets;
		},
		...mapState(usePlanStore, ['plans']),
	},
	methods: {
		handlePlanPurchased() {
			this.loading = false;
			this.wantToBuyAnotherTicket = false;
		},
	},
});
