
import { defineComponent } from 'vue';
import TicketPurchaseComponent from './TicketPurchaseComponent.vue';
import SidebarComponent from './presentation/SidebarComponent.vue';
import { usePaymentStore } from '@/stores/paymentStore';
import { mapState } from 'pinia';
import CalendarAddComponent from './CalendarAddComponent.vue';
import { ChargebeePlanIdEnum } from '@/enums/ChargebeePlanIdEnum';

export default defineComponent({ 
	components: { SidebarComponent, TicketPurchaseComponent, CalendarAddComponent } ,
	data() {
		return {
			ChargebeePlanIdEnum,
		}
	},
	computed: {
		...mapState(usePaymentStore, ['loading']),
	},
});
