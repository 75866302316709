
import { defineComponent } from 'vue';
import ModalComponent from '@/components/ModalComponent.vue';
import WidgetContainerComponent from '@/components/presentation/WidgetContainerComponent.vue';
	
export default defineComponent({
	components: { ModalComponent, WidgetContainerComponent },
	data() {
		return {
			open: false,
		}
	},
});
