import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-07c8c763"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["data-streak-health"]
const _hoisted_2 = { class: "streak-rank" }
const _hoisted_3 = { class: "streak-length" }
const _hoisted_4 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_person_avatar = _resolveComponent("person-avatar")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["streak", { 'streak-current-user': _ctx.streak.User.UserID === _ctx.currentUser.UserID }]),
    "data-streak-health": _ctx.streak.User.UserID === _ctx.currentUser.UserID ? _ctx.streak.Health : ' '
  }, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.rankDisplay), 1 /* TEXT */),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.streak.Length), 1 /* TEXT */),
    _createElementVNode("a", {
      class: "streak-user-name",
      href: _ctx.streak.User.UserID === _ctx.currentUser.UserID ? '/dashboard' : _ctx.streak.User.ProfileURL,
      target: "_blank"
    }, _toDisplayString(_ctx.streak.User.DisplayName), 9 /* TEXT, PROPS */, _hoisted_4),
    _createVNode(_component_person_avatar, {
      class: "streak-avatar",
      user: _ctx.streak.User
    }, null, 8 /* PROPS */, ["user"])
  ], 10 /* CLASS, PROPS */, _hoisted_1))
}