import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-48796cf0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "input-wrapper" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["data-matches"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _withDirectives(_createElementVNode("input", {
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
        type: "text",
        placeholder: "Search Curriculum",
        onKeyup: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.debouncedSearch && _ctx.debouncedSearch(...args))),
        onChange: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.debouncedSearch && _ctx.debouncedSearch(...args)))
      }, null, 544 /* HYDRATE_EVENTS, NEED_PATCH */), [
        [_vModelText, _ctx.search]
      ]),
      (_ctx.search.length > 0)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            class: "clear",
            onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => {_ctx.search = '';}, ["prevent"]))
          }, " × "))
        : _createCommentVNode("v-if", true)
    ]),
    (_ctx.search)
      ? (_openBlock(), _createElementBlock("ul", _hoisted_2, [
          (_ctx.visibleResults.length > 0)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.visibleResults, (result) => {
                  return (_openBlock(), _createElementBlock("li", {
                    key: result.id,
                    "data-matches": result.matches
                  }, [
                    _createVNode(_component_router_link, {
                      to: { name: 'wikiView', params: { id: result.id, slug: result.stub } },
                      title: `${result.title}. Relevancy: ${result.matches}. `
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", {
                          class: "result-title",
                          innerHTML: result.title
                        }, null, 8 /* PROPS */, _hoisted_4),
                        (result.body)
                          ? (_openBlock(), _createElementBlock("div", {
                              key: 0,
                              class: "result-body",
                              innerHTML: result.body
                            }, null, 8 /* PROPS */, _hoisted_5))
                          : _createCommentVNode("v-if", true)
                      ]),
                      _: 2 /* DYNAMIC */
                    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to", "title"])
                  ], 8 /* PROPS */, _hoisted_3))
                }), 128 /* KEYED_FRAGMENT */)),
                (_ctx.results.length > 5)
                  ? (_openBlock(), _createElementBlock("li", _hoisted_6, [
                      _createElementVNode("button", {
                        class: "btn-a",
                        onClick: _cache[4] || (_cache[4] = _withModifiers(($event: any) => (_ctx.showAllResults = !_ctx.showAllResults), ["prevent"]))
                      }, [
                        (_ctx.results.length !== _ctx.visibleResults.length)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_7, "Show more"))
                          : (_openBlock(), _createElementBlock("span", _hoisted_8, "Collapse"))
                      ])
                    ]))
                  : _createCommentVNode("v-if", true)
              ], 64 /* STABLE_FRAGMENT */))
            : (_openBlock(), _createElementBlock("li", _hoisted_9, " No results "))
        ]))
      : _createCommentVNode("v-if", true)
  ]))
}