
import { defineComponent } from 'vue';
import type { PropType } from 'vue';

import { useAnnouncementsStore } from '@/stores/announcementStore';
import type { Announcement } from '@/interfaces/AnnouncementInterface';

let firstMarginSet = false;
let $wrapper: HTMLDivElement | null;

export default defineComponent({
	props: {
		announcements: {
			type: Object as PropType<Array<Announcement>>,
			default: [] as Announcement[],
		},
	},
	mounted() {
		const store = useAnnouncementsStore();
		
		if(window.innerWidth >= 979) {
			store.$subscribe(() => {

				// use next tick to ensure that when an announcment is hidden, we don't query it
				// to see where to animate the body margin to
				this.$nextTick(() => {
					this.updateContainerHeight();
				})
			});
		}

		store.setAnnouncements(this.announcements);
	},
	methods: {
		updateContainerHeight() {
			$wrapper = document.querySelector<HTMLDivElement>('.axero-community-wrapper');
			if($wrapper == null) {
				return;
			}

			const elementsHeight = (Array.from(document.querySelectorAll('.thought-announcement:not(.hiding), #axero-header > .container-fluid')) as HTMLElement[]).reduce((acc, el) => {
				return acc + el.clientHeight;
			}, 0);
			
			if(firstMarginSet) {
				$wrapper.style.transition = 'margin-top 0.3s';
			}

			$wrapper.style.marginTop = ((40 + elementsHeight) + 'px');

			firstMarginSet = true;
		},
	},
})
