import { BeltLevel } from '@/interfaces/BeltLevelInterface';

export const beltLevels: BeltLevel[] = [
	{
		color: 'none',
		lowerThreshold: -11,
		upperThreshold:	-1,
		colorValue: '#ffffff',
	},
	{
		color: 'clear',
		lowerThreshold: 0,
		upperThreshold:	9999.99,
		colorValue: '#ffffff',
	},
	{
		color: 'white',
		lowerThreshold: 10000,
		upperThreshold:	19999.99,
		colorValue: '#ffffff',
	},
	{
		color: 'yellow',
		lowerThreshold: 20000,
		upperThreshold:	29999.99,
		colorValue: '#ffe600',
	},
	{
		color: 'green',
		lowerThreshold: 30000,
		upperThreshold:	39999.99,
		colorValue: '#00B242',
	},
	{
		color: 'blue',
		lowerThreshold: 40000,
		upperThreshold:	49999.99,
		colorValue: '#0080c6',
	},
	{
		color: 'red',
		lowerThreshold: 50000,
		upperThreshold:	59999.99,
		colorValue: '#d2232a',
	},
	{
		color: 'black',
		lowerThreshold: 60000,
		upperThreshold:	9999999,
		colorValue: '#000000',
	},
];