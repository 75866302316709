
import { defineComponent } from 'vue';
import { axiosClient } from '@/utils/AxiosUtil';
import type { Streak } from '@/interfaces/StreakInterface';
import type { ApiResponseStreakLeaderboard } from '@/interfaces/ApiResponseStreakLeaderboardInterface';
import StreakComponent from '@/components/StreakComponent.vue';

export default defineComponent({
	components: {
		StreakComponent,
	},
	data() {
		return {
			topStreaks: [] as Streak[],
			relativeStreaks: [] as Streak[],
		}
	},
	beforeMount() {
		this.loadUsers();
	},
	methods: {
		async loadUsers() {
			const { data } = await axiosClient.post<ApiResponseStreakLeaderboard>('/streak/get-streak-leaderboard');

			this.topStreaks = data.topStreaks;
			this.relativeStreaks = data.relativeStreaks;
		},
	},
})
