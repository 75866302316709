
import { defineComponent } from 'vue';
import TicketPurchaseComponent from './TicketPurchaseComponent.vue';
import SidebarComponent from './presentation/SidebarComponent.vue';
import { usePaymentStore } from '@/stores/paymentStore';
import { mapState } from 'pinia';
import CalendarAddComponent from './CalendarAddComponent.vue';
import { ChargebeePlanIdEnum } from '@/enums/ChargebeePlanIdEnum';

export default defineComponent({
	components: { SidebarComponent, TicketPurchaseComponent, CalendarAddComponent },
	props: {
		hasBbpAccess: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		planIds() {
			return this.hasBbpAccess ? [ ChargebeePlanIdEnum.Fiji2023BbUpfront, ChargebeePlanIdEnum.Fiji2023BbPartPay] : [ChargebeePlanIdEnum.Fiji2023TlbsUpfront, ChargebeePlanIdEnum.Fiji2023TlbsPartPay];
		},
		...mapState(usePaymentStore, ['loading']),
	},
});
