
import { defineComponent } from 'vue';
import LogoComponent from '@/components/main/LogoComponent.vue';
import AuthAdminComponent from '@/components/main/auth/AuthAdminComponent.vue';
import { currentUser } from '@/data/currentUserData';

export default defineComponent({
	components: { LogoComponent, AuthAdminComponent },
	data() {
		return {
			currentUser,
		};
	},
});
