
import { defineComponent, type PropType } from 'vue';
import { mapState } from 'pinia';
import type { Wiki } from '@/interfaces/WikiInterface';
import { useWikiStore } from '@/stores/wikiStore';

export default defineComponent({
	props: {
		wiki: {
			type: Object as PropType<Wiki>,
			default: {} as Wiki,
		},
	},
	data() {
		return {
			clickedOpen: false,
		}
	},
	computed: {
		open(): boolean {
			return this.isCurrent || this.hasActiveChild || this.clickedOpen;
		},
		isCurrent(): boolean {
			return this.wiki.ContentID === this.currentWiki.ContentID;
		},
		isRoot(): boolean {
			return this.wiki.ParentContentID <= 0;
		},
		hasActiveChild(): boolean {
			if(!this.wiki.children) {
				return false;
			}

			return this.wiki.children.some(c => c.ContentID === this.currentWiki.ContentID);
		},
		result(): string {
			if(this.wiki && this.learningResults && (this.wiki.ContentID in this.learningResults)) {
				return `lesson-status-${this.learningResults[this.wiki.ContentID].comment_approved}`; 
			}

			return '';
		},
		...mapState(useWikiStore, ['currentWiki', 'learningResults']),
	},
});
