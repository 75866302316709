
import { defineComponent } from 'vue';
import type { PropType } from 'vue';
import type { UserWithBeltAndLocation } from '@/interfaces/UserWithBeltAndLocationInterface';

export default defineComponent({
	props: {
		user: {
			type: Object as PropType<UserWithBeltAndLocation>,
			default: {} as UserWithBeltAndLocation,
		},
	},
})
