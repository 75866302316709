export const expertiseData = [
	'Business and Finance',
	'Communication and Presentation',
	'Data and Risk',
	'Diversity and Inclusion',
	'Emotional Intelligence',
	'EQ and Influence',
	'Innovation and Change',
	'Mindset and Productivity',
	'People and Leadership',
	'Personal Development',
	'Sales and Marketing',
	'Service and Engagement',
	'Strategy and Culture',
	'Systems and Design Thinking',
	'Teams and Performance',
];