<template>
	<div class="container">
		<h2>Commitment Links</h2>
		<ul>
			<li>
				<router-link :to="{ name: 'schoolJoin' }">
					Join The School
				</router-link>
				<br>
				<ul>
					<li>Link: /public/v/join-the-school</li>
					<li>Public page, accessible if not logged in</li>
					<li>asd</li>
				</ul>
			</li>

			<li>
				<router-link :to="{ name: 'schoolRenewal' }">
					Alumni School Renewal
				</router-link>
				<br>
				<ul>
					<li>Link: /public/v/alumni-school-renewal</li>
					<li>Public page, accessible if not logged in</li>
					<li>Is for ex-TLBS student (TLBS alumni) who want to return</li>
					<li>They are charged a pro-rata fee, and then the 1k starts the next month</li>
				</ul>
			</li>

			<li>
				<a
					href="/spaces/2/the-school/tl/tlbs-offer-renewal-pif"
					target="_blank"
				>TLBS Offer Renewal PIF</a><br>
				<ul>
					<li>Link: /spaces/2/the-school/tl/tlbs-offer-renewal-pif</li>
					<li>Only available to those still in BS space</li>
					<li>Pay in full renewal offer</li>
				</ul>
			</li>

			<li>
				<a
					href="/tl/tlbs-offer-alumni-pif"
					target="_blank"
				>TLBS Offer Alumni PIF</a><br>
				<ul>
					<li>Link: /tl/tlbs-offer-alumni-pif</li>
					<li>Available to anyone who is logged in, don't need a space</li>
					<li>Pay in full renewal offer</li>
				</ul>
			</li>
			
			<li>
				<a
					href="/spaces/15/the-curriculum/tl/commitment-agreement"
					target="_blank"
				>The School Commitment Agreement</a><br>
				<ul>
					<li>Link: /spaces/15/the-curriculum/tl/commitment-agreement</li>
					<li>Available to those in FP space</li>
					<li>Main commitment agreement for TLBS</li>
				</ul>
			</li>
		</ul>
	</div>
</template>