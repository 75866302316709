
import { defineComponent } from 'vue';
	
export default defineComponent({
	props: {
		disabled: {
			type: Boolean,
			default: false,
		},
		secondary: {
			type: Boolean,
			default: false,
		},
		small: {
			type: Boolean,
			default: false,
		},
	},
});
