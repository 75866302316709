import { currentUser } from '@/data/currentUserData';
import { isDebug } from '@/utils/GeneralUtils';

export const hubspotScript = () => {
	// HS meeting script
	const hubspotMeetingScript = document.createElement('script');
	hubspotMeetingScript.src = '//static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js';
	hubspotMeetingScript.setAttribute('defer', '');
	hubspotMeetingScript.setAttribute('async', '');
	hubspotMeetingScript.setAttribute('id', 'hs-meeting-script');
	hubspotMeetingScript.setAttribute('type', 'text/javascript');
	hubspotMeetingScript.setAttribute('data-origin', 'created-by-webpack-hubspot.js');
	document.getElementsByTagName('head')[0].appendChild(hubspotMeetingScript);


	if (isDebug()) {
		return;
	}

	// HS tracking script
	const hubspotScript = document.createElement('script');
	hubspotScript.src = '//js.hs-scripts.com/8329346.js';
	hubspotScript.setAttribute('defer', '');
	hubspotScript.setAttribute('async', '');
	hubspotScript.setAttribute('id', 'hs-script-loader');
	hubspotScript.setAttribute('type', 'text/javascript');
	hubspotScript.setAttribute('data-origin', 'created-by-webpack-hubspot.js');

	document.getElementsByTagName('head')[0].appendChild(hubspotScript);

	if (currentUser.Email) {
		// https://developers.hubspot.com/docs/api/events/tracking-code#identify-a-visitor
		const _hsq = window._hsq = window._hsq || [];
		_hsq.push(['identify', {
			email: currentUser.Email,
		}]);
	}
}