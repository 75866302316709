
import { defineComponent } from 'vue';
import ToolTipComponent from '@/components/ToolTipComponent.vue';

export default defineComponent({
	components: { ToolTipComponent },
	props: {
		trigger: {
			type: String,
			default: '',
		},
		content: {
			type: String,
			default: '',
		},
	},
})
