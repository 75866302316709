import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_announcement_single = _resolveComponent("announcement-single")!

  return (_ctx.hasAnyVisibleAnnouncements)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["thought-announcement-container", `thought-announcement-container-type-${_ctx.type}`])
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.announcements, (announcement) => {
          return (_openBlock(), _createBlock(_component_announcement_single, {
            key: announcement.AnnouncementID,
            announcement: announcement
          }, null, 8 /* PROPS */, ["announcement"]))
        }), 128 /* KEYED_FRAGMENT */))
      ], 2 /* CLASS */))
    : _createCommentVNode("v-if", true)
}