import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, vShow as _vShow, withCtx as _withCtx, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3254408e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "video" }
const _hoisted_2 = ["src"]
const _hoisted_3 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ModalComponent = _resolveComponent("ModalComponent")!

  return (_ctx.broadcast.ID)
    ? _withDirectives((_openBlock(), _createBlock(_component_ModalComponent, {
        key: 0,
        "hide-header": true,
        "hide-footer": true,
        "hide-close": true,
        class: "broadcast-modal",
        onClose: _ctx.markRead
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("iframe", {
              src: _ctx.broadcast.VideoURL + '?videoFoam=true',
              allowtransparency: "true",
              frameborder: "0",
              scrolling: "no",
              class: "wistia_embed",
              name: "wistia_embed",
              allowfullscreen: "",
              mozallowfullscreen: "",
              webkitallowfullscreen: "",
              oallowfullscreen: "",
              msallowfullscreen: ""
            }, null, 8 /* PROPS */, _hoisted_2)
          ]),
          _createElementVNode("div", {
            class: "copy",
            textContent: _toDisplayString(_ctx.broadcast.Copy)
          }, null, 8 /* PROPS */, _hoisted_3),
          _createElementVNode("button", {
            class: "btn",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.markRead && _ctx.markRead(...args)))
          }, " Close ")
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["onClose"])), [
        [_vShow, _ctx.open]
      ])
    : _createCommentVNode("v-if", true)
}