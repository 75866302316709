
import { defineComponent } from 'vue';
import type { PropType } from 'vue';
import ToolTipComponent from '@/components/ToolTipComponent.vue';
import type { ProgressBar } from '@/interfaces/ProgressBarInterface';
import BaseActivityName from '@/enums/BaseActivityName';
import ProgressFor from '@/enums/ProgressFor';

export default defineComponent({
	components: {
		ToolTipComponent,
	},
	props: {
		progressBar: {
			type: Object as PropType<ProgressBar>,
			default: {} as ProgressBar,
		},
	},
	data() {
		return {
			BaseActivityName,
			ProgressFor,
		}
	},
})
