
import { format, addDays } from 'date-fns';
import * as pluralize from 'pluralize';
import { defineComponent } from 'vue';

// hours*minutes*seconds*milliseconds
const oneDay = 24 * 60 * 60 * 1000; 
const cutoffDate = new Date(2022, 3, 27);
const secondCutoffDate = new Date(2022, 3, 29);
const now = new Date();

export default defineComponent({
	computed: {
		dayStringPlural: function(): string {
			return pluralize('day', this.daysUntilCutoff)
		},
		daysUntilCutoff: function(): number {
			return Math.round((cutoffDate.valueOf() - now.valueOf()) / oneDay) + 1;
		},
		cutoffDateFormatted: function(): string {
			return format(cutoffDate, 'LLLL do')
		},
		secondCutoffDateFormatted: function(): string {
			return format(addDays(secondCutoffDate, 1), 'LLLL do')
		},
		shouldShowSecondCutoff: function(): boolean {
			return this.daysUntilCutoff <= 0;
		},
		shouldShowAtAll: function(): boolean {
			return Math.round((secondCutoffDate.valueOf() - now.valueOf()) / oneDay) + 1 >= 0;
		},
	},
});
