
import { defineComponent } from 'vue';
import { currentUser } from '@/data/currentUserData';

export default defineComponent({
	props: {
		not: {
			default: false,
			type: Boolean,
		},
	},
	data() {
		return {
			currentUser,
		};
	},
	computed: {
		allowed() {
			return this.not ? !currentUser.IsAdmin : currentUser.IsAdmin;
		},
	},
});
