import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-32650b02"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "vue-tl-hero-image-component" }
const _hoisted_2 = { class: "hero-content" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.subTitle)
        ? (_openBlock(), _createElementBlock("h3", _hoisted_3, _toDisplayString(_ctx.subTitle), 1 /* TEXT */))
        : _createCommentVNode("v-if", true),
      (_ctx.title)
        ? (_openBlock(), _createElementBlock("h1", _hoisted_4, _toDisplayString(_ctx.title), 1 /* TEXT */))
        : _createCommentVNode("v-if", true)
    ])
  ]))
}